// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../../static/mobileassets/arrow_left.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActiveTagHeader{max-width:100%}.ActiveTagHeader h1{color:#000;display:flex;flex-wrap:nowrap;font-family:"UbuntuBold",Arial,Helvetica,sans-serif;font-size:19px;line-height:normal;margin:0px;max-height:45px;overflow:hidden;position:relative;text-wrap:wrap;word-break:break-word}.ActiveTagHeader h1::before{background:rgba(0,0,0,0) url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;background-size:cover;content:"";display:block;flex:0 0 auto;height:24px;width:25px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
