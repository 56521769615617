import type React from "react"
import { useAppSelector } from "../../../store/hooks"
import { selectTokenBalance } from "../../../store/userSlice"
import { RecordButton } from "../RecordButton"
import "./MobileBroadcastLandscapeUI.scss"
import { TokenCount } from "../TokenCount"

interface MobileBroadscastLandscapeUIProps {
    isStreaming: boolean
    onRecordButtonClick: () => void
}

export const MobileBroadcastLandscapeUI: React.FC<
    MobileBroadscastLandscapeUIProps
> = ({ onRecordButtonClick, isStreaming }) => {
    const tokenBalance = useAppSelector(selectTokenBalance)

    return (
        <div
            className="MobileBroadcastLandscapeUI"
            data-testid="mobilebroadcast-landscape-ui"
        >
            <div className="MobileBroadcastLandscapeUI__TopSection">
                <TokenCount tokenBalance={tokenBalance ?? 0} />
            </div>
            <div className="MobileBroadcastLandscapeUI__BottomSection">
                <RecordButton
                    onClick={onRecordButtonClick}
                    isStreaming={isStreaming}
                />
            </div>
        </div>
    )
}
