import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgMutedMessage = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#B3B3B3"
            d="M3.6 12.4a.6.6 0 1 1 0-1.2H4v-4q0-.683.225-1.308.225-.626.608-1.175L7.317 7.2H5.6L1.925 3.525a.607.607 0 1 1 .858-.858l10.443 10.458a.601.601 0 0 1-.851.85L10.8 12.4zm8.4-2.2L5.717 3.9q.333-.233.708-.383t.775-.234V2.4q0-.333.233-.567A.77.77 0 0 1 8 1.6q.333 0 .567.233A.77.77 0 0 1 8.8 2.4v.883a3.78 3.78 0 0 1 2.308 1.367Q12 5.75 12 7.2zm-4 4.2q-.5 0-.85-.35a1.16 1.16 0 0 1-.35-.85h2.4q0 .5-.35.85T8 14.4"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgMutedMessage)
const Memo = memo(ForwardRef)
export default Memo
