import { useEffect, useState } from "react"
import { SURVEY_CONFIGS } from "./constants"
import {
    SurveyIntroMain,
    SurveyTypeOutro,
    SurveyTypeRange,
    SurveyTypeText,
} from "./SurveyType"
import type { SurveyId } from "./constants"
import "./Survey.scss"

type SurveyProps = {
    type: SurveyId
    closeModal: ({
        skipAction,
        hasData,
    }: {
        skipAction: boolean
        hasData: boolean
    }) => void
    onSubmit: (resp: string[], prompts: string[]) => void
}

export function Survey({ type, closeModal, onSubmit }: SurveyProps) {
    const surveyStepsConfig = SURVEY_CONFIGS[type]
    const [answers, setAnswers] = useState<Record<string, string>>({})

    const [currentStep, setCurrentStep] = useState(0)

    // setup answers object
    useEffect(() => {
        function setupAnswers() {
            const answers = surveyStepsConfig
                .filter((q) => q.id !== "intro" && q.id !== "outro")
                .reduce((acc, question) => {
                    return { ...acc, [question.id]: "" }
                }, {})
            setAnswers(answers)
        }
        setupAnswers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onNext = () => {
        setCurrentStep((prev) => prev + 1)
    }
    const onAnswerChange = (id: string, value: string) => {
        setAnswers((prev) => ({ ...prev, [id]: value }))
    }

    const onCancelClick = (skipAction = false) => {
        const didAnswerSomeQuestions =
            Object.values(answers).filter(Boolean).length > 0
        closeModal({ skipAction, hasData: didAnswerSomeQuestions })
    }

    const onRatingClick = (id: string, value: string) => {
        onAnswerChange(id, value)
        onNext()
    }

    const onBack = () => {
        setCurrentStep((prev) => prev - 1)
    }

    const onSubmitHandler = () => {
        // cleanup new lines from textarea
        const surveyAnswers = Object.values(answers).map((answer) =>
            answer.replace(/(?:\r\n|\r|\n)/g, ""),
        )
        const surveyQuestions = surveyStepsConfig
            .filter(
                (question) =>
                    question.id !== "intro" && question.id !== "outro",
            )
            .map((question) => question.plainText)

        onSubmit(surveyAnswers, surveyQuestions)
        // switching to outro last step
        onNext()
    }

    const stepToRender = surveyStepsConfig[currentStep]
    const isSubmitableStep = stepToRender.isSubmit
    const isOutroStep = stepToRender.questionType === "outro"
    const totalQuestions = surveyStepsConfig.length - 2
    const onNextToPass = isSubmitableStep
        ? onSubmitHandler
        : isOutroStep
          ? () => onCancelClick(true)
          : onNext
    const onBackToPass = currentStep === 0 ? () => onCancelClick() : onBack
    const commonProps = {
        key: stepToRender.id,
        onBack: onBackToPass,
        onNext: onNextToPass,
        onRatingClick,
        totalQuestions,
        question: stepToRender,
        selectedVal: answers[stepToRender.id],
        onChange: onAnswerChange,
    }

    if (stepToRender.questionType === "intro") {
        return <SurveyIntroMain {...commonProps} />
    }

    if (stepToRender.questionType === "outro") {
        return <SurveyTypeOutro {...commonProps} />
    }
    if (stepToRender.questionType === "range") {
        return <SurveyTypeRange {...commonProps} />
    }
    // by default render text type
    return <SurveyTypeText {...commonProps} />
}
