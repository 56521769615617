import type React from "react"
import { t } from "@lingui/macro"
import { gettext } from "@multimediallc/web-utils"
import { useFeatureFlag } from "../../../hooks/appContext"
import { KeyboardEventHandlerChain } from "../../../utils/keyboardEventHandler"
import { Overlay } from "../../common/atoms/Overlay"
import { AbuseReportForm } from "../AbuseReportForm"
import "./AbuseReportModal.scss"

const i18n = {
    reportAbuseText: gettext("Report Room"),
}

interface AbuseReportModalProps {
    /**
     * A callback to run when the close button is clicked, or when escape is pressed.
     */
    closeModal: () => void
    /**
     * The roomname to pass through to `AbuseReportForm` for constructing the api link.
     */
    roomName: string
    /**
     * An optional test id to pass. Really only used inside jest tests. Will be rendered onto the root div
     * of the modal, not the overlay.
     */
    testId?: string
}

export function AbuseReportModal(props: AbuseReportModalProps) {
    const linguiMigrate = useFeatureFlag("LinguiMigrate").active
    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation()
        handlers.execute(event)
    }

    const handlers = new KeyboardEventHandlerChain()
    handlers.addHandler({
        keyCode: 27, // Escape key
        requiresCtrlOrMeta: false,
        handle: () => {
            props.closeModal()
        },
    })

    const overlayClickHandler = (
        // While this is _way_ more verbose than just duplicating the type, it means we use
        // the typing in `Overlay` as a single source of truth.
        event: Parameters<
            React.ComponentPropsWithoutRef<typeof Overlay>["onClick"]
        >[0],
    ) => {
        event.stopPropagation()
        props.closeModal()
    }

    return (
        <>
            <Overlay onClick={overlayClickHandler} />
            <div
                id="AbuseReportModal"
                className="AbuseReportModal"
                onKeyDown={keyDownHandler}
                data-testid={props.testId}
            >
                <div className="divot">
                    <div className="divotBorder">
                        <div className="divotBackground" />
                    </div>
                </div>
                <div className="header">
                    {linguiMigrate ? t`Report Room` : i18n.reportAbuseText}
                </div>
                <AbuseReportForm
                    closeModal={props.closeModal}
                    roomName={props.roomName}
                />
            </div>
        </>
    )
}
