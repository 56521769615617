import { useNavigate } from "react-router-dom"
import { IconButton } from "../common/IconButton/IconButton"
import { getMobileSvgPath } from "../utils"
import styles from "./MessageProfileAlert.module.scss"

type Props = {
    show: boolean
    onClose: () => void
}

export const MessageProfileAlert: React.FC<Props> = ({ show, onClose }) => {
    const navigate = useNavigate()
    if (!show) return null

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation()
        onClose()
    }
    return (
        <div
            className={styles.messageProfileAlert}
            onClick={() => navigate("/settings")}
            data-testid="message-profile-alert"
        >
            <img
                className={styles.setupIcon}
                src={getMobileSvgPath("setup")}
                alt="Setup"
            />
            <div className={styles.message}>
                <h2>Set up your message profile</h2>
                <p>
                    Once your profile is complete, you may be featured in the
                    message recommendation list.
                </p>
            </div>
            <IconButton
                icon={getMobileSvgPath("close-orange")}
                iconAlt="Close"
                onClick={handleClose}
                className={styles.closeIcon}
            />
        </div>
    )
}
