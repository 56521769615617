import { useCallback, useEffect, useRef, useState } from "react"
import { throttle } from "lodash"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    DM_THREAD_PAGE_LIMIT,
    messagingApi,
    useGetThreadsQuery,
} from "../../../store/messagingSlice"
import { Spinner } from "../../common/atoms/Spinner"
import { MessageProfileAlert } from "../MessageProfileAlert/MessageProfileAlert"
import { MessageThread } from "../MessageThread/MessageThread"
import { getMobileSvgPath } from "../utils"
import styles from "./ConversationList.module.scss"
import type { Thread } from "../types"

const SCROLL_THRESHOLD = 20
const THROTTLE_DELAY = 150

type Props = {
    onClose: () => void
}

export const ConversationList: React.FC<Props> = ({ onClose }) => {
    const [showProfileAlert, setShowProfileAlert] = useState(true)
    const dispatch = useAppDispatch()
    const contentRef = useRef<HTMLDivElement>(null)
    const scrollPosition = useAppSelector(
        (state) => state.messaging.conversationListScroll,
    )

    const lastQuery = useAppSelector(
        (state) =>
            messagingApi.endpoints.getThreads.select({
                offset: 0,
                limit: DM_THREAD_PAGE_LIMIT,
            })(state).originalArgs,
    )

    const {
        data: currentData,
        isLoading,
        isFetching,
        error,
    } = useGetThreadsQuery({
        offset: lastQuery?.offset ?? 0,
        limit: DM_THREAD_PAGE_LIMIT,
    })

    // Restore scroll position
    useEffect(() => {
        if (scrollPosition && contentRef.current) {
            contentRef.current.scrollTop = scrollPosition
        }
    }, [scrollPosition])

    const handleScroll = useCallback(
        throttle(() => {
            const contentElement = contentRef.current
            if (!contentElement) return

            const { scrollTop, scrollHeight, clientHeight } = contentElement
            if (
                scrollHeight - scrollTop - clientHeight < SCROLL_THRESHOLD &&
                !isFetching &&
                currentData?.has_more
            ) {
                dispatch(
                    messagingApi.endpoints.getThreads.initiate({
                        offset: (lastQuery?.offset ?? 0) + DM_THREAD_PAGE_LIMIT,
                        limit: DM_THREAD_PAGE_LIMIT,
                    }),
                )
            }
        }, THROTTLE_DELAY),
        [isFetching, currentData?.has_more, lastQuery?.offset, dispatch],
    )

    useEffect(() => {
        const contentElement = contentRef.current
        if (!contentElement) return

        contentElement.addEventListener("scroll", handleScroll)
        return () => {
            contentElement.removeEventListener("scroll", handleScroll)
            handleScroll.cancel()
        }
    }, [handleScroll])

    return (
        <div
            className={styles.conversationList}
            ref={contentRef}
            data-testid="conversation-list"
        >
            <div className={styles.conversationListHeader}>
                <div className={styles.conversationListHeaderTitle}>
                    Messages
                </div>
                <button
                    className={styles.closeButton}
                    onClick={onClose}
                    data-testid="close-button"
                >
                    <img
                        src="/static/tsdefaultassets/black-close.svg"
                        alt="Close"
                    />
                </button>
            </div>
            <div className={styles.conversationListFilter}>
                <div className={styles.avatar}>
                    <img src={getMobileSvgPath("avatar")} alt="Avatar" />
                </div>
                <div className={styles.filterInputWrapper}>
                    <img src={getMobileSvgPath("magnifying-glass")} />
                    <input
                        type="text"
                        placeholder="Search usernames"
                        className={styles.filterInput}
                    />
                </div>
                <button className={styles.filterButton}>
                    <img src={getMobileSvgPath("filter")} alt="Filter" />
                </button>
            </div>
            <MessageProfileAlert
                show={showProfileAlert}
                onClose={() => setShowProfileAlert(false)}
            />
            <div>
                {isLoading ? (
                    <div className={styles.conversationListMessage}>
                        Loading...
                    </div>
                ) : error ? (
                    <div className={styles.conversationListMessage}>
                        Error loading messages
                    </div>
                ) : !currentData?.threads?.length ? (
                    <div className={styles.conversationListMessage}>
                        No messages
                    </div>
                ) : (
                    <>
                        {currentData.threads.map((thread: Thread) => (
                            <MessageThread
                                key={`${thread.from_user}-${thread.created_at}`}
                                thread={thread}
                                conversationListRef={contentRef}
                            />
                        ))}
                        {isFetching && (
                            <div className={styles.loadingIndicator}>
                                <Spinner size="xspx" />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
