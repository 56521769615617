import {
    isPrivateSpyBadgesActive,
    SpyShowPrices,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../../hooks/urlStateContext"
import { FilterSection } from "../FilterSection"

interface SpyPriceSectionContentProps {
    combinedOverlay?: boolean
}

export const SpyPriceToButtonText = new Map<SpyShowPrices, string>([
    [SpyShowPrices.Value6, "6 tks"],
    [SpyShowPrices.Range12to18, "12 - 18 tks"],
    [SpyShowPrices.Range30to42, "30 - 42 tks"],
    [SpyShowPrices.Range54to66, "54 - 66 tks"],
    [SpyShowPrices.Range90Plus, "90+ tks"],
])

export const SpyPriceSection = () => {
    const { spyShowPrices } = useUrlStateContext()
    const privateSpyBadgeActive = isPrivateSpyBadgesActive()
    const clearButtonText = privateSpyBadgeActive ? "Clear all" : "Clear"
    return (
        <FilterSection
            title="Spy Show (tokens per minute)"
            onClear={() => {
                if ((spyShowPrices ?? []).length === 0) {
                    return
                }
                UrlState.current.setPartialState({ spyShowPrices: undefined })
            }}
            isClearDisabled={(spyShowPrices ?? []).length === 0}
            testId="spy-prices-mobile-filters-section"
            clearButtonText={clearButtonText}
        >
            <SpyPriceSectionContent />
        </FilterSection>
    )
}

export const SpyPriceSectionContent = ({
    combinedOverlay = false,
}: SpyPriceSectionContentProps) => {
    const { spyShowPrices } = useUrlStateContext()
    const privateSpyBadgeActive = isPrivateSpyBadgesActive()

    return (
        <div
            className={`FilterSection__filterOptions${combinedOverlay ? "Combined" : ""}`}
        >
            {Object.entries(SpyShowPrices).map(([key, spyPrice]) => (
                <button
                    key={key}
                    data-testid={`spy-prices-filter-option-${spyPrice}`}
                    className={`FilterSection__filterOption ${
                        spyShowPrices?.includes(spyPrice) ? "active" : ""
                    }`}
                    onClick={() =>
                        UrlState.current.setPartialState({
                            spyShowPrices: spyShowPrices?.includes(spyPrice)
                                ? spyShowPrices.filter(
                                      (item) => item !== spyPrice,
                                  )
                                : privateSpyBadgeActive
                                  ? [...(spyShowPrices || []), spyPrice]
                                  : [spyPrice],
                        })
                    }
                >
                    {SpyPriceToButtonText.get(spyPrice)}
                </button>
            ))}
        </div>
    )
}
