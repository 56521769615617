import "./SearchAutocomplete.scss"
import { Sizes } from "../../../../../../interfaces/sizing"
import { Typography } from "../../../../../common"

export interface IAutocompleteItem {
    value: string
    displayName: string
}

interface SearchAutocompleteProps {
    items: IAutocompleteItem[]
    onItemClick: (value: string) => void
    loading: boolean
    maxShownSuggestions?: number
}

export function SearchAutocomplete({
    items,
    onItemClick,
    loading,
    maxShownSuggestions,
}: SearchAutocompleteProps) {
    return (
        <div className="SearchAutocomplete" data-testid="search-autocomplete">
            {items.length > 0 ? (
                <>
                    {items
                        .slice(
                            0,
                            maxShownSuggestions
                                ? maxShownSuggestions
                                : undefined,
                        )
                        .map((item, index) => (
                            <div
                                className="SearchAutocomplete__item"
                                data-testid={`search-autocomplete-item-${index}`}
                                onClick={(ev) => {
                                    ev.stopPropagation()
                                    onItemClick(item.value)
                                }}
                                key={item.value}
                            >
                                {item.displayName}
                            </div>
                        ))}
                </>
            ) : (
                <Typography
                    className="SearchAutocomplete__item SearchAutocomplete__result"
                    component="div"
                    size={Sizes.xs}
                >
                    {loading ? "Loading..." : "Not Found"}
                </Typography>
            )}
        </div>
    )
}
