import { useEffect, useRef, useState } from "react"
import type React from "react"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../../../hooks/urlStateContext"
import { useDebounce } from "../../../../../../../hooks/useDebounce"
import { error } from "../../../../../../../utils/debug"
import { Search } from "../../../../../../common/atoms/Icons/Others"
import "../../filterSearch.scss"
import { useFiltersContext } from "../../../FiltersContext"
import { SearchAutocomplete } from "../../SearchAutocomplete"
import { loadTagsFromSearch } from "../hashtagApiUtils"
import type { IAutocompleteItem } from "../../SearchAutocomplete/SearchAutocomplete"

const MAX_INPUT_LENGTH = 50
const MAX_SHOWN_SUGGESTIONS = 10

export const HashtagSearch: React.FC = () => {
    const { topTags, tagSearchInput, setTagSearchInput } = useFiltersContext()

    const [tagsFromSearch, setTagsFromSearch] = useState<string[]>([])
    const [autocompleteItems, setAutocompleteItems] = useState<
        IAutocompleteItem[]
    >([])
    const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const searchInputElement = useRef<HTMLInputElement>(null)

    const urlStateContext = useUrlStateContext()
    const selectedHashtag = urlStateContext.tags?.[0] ?? ""

    const autoCompleteRef = useRef<HTMLDivElement>(null)

    const getSearchWord = (): string => {
        // Use this to get sanitized input value to be submitted to api or as set hashtag value.
        return tagSearchInput.replace(/#/g, "").toLowerCase()
    }
    const debouncedSearchValue = useDebounce<string>(getSearchWord(), 100)

    useEffect(() => {
        setIsLoading(true)
        loadTagsFromSearch(debouncedSearchValue)
            .then((tags: string[]) => {
                const shownTags = tags
                    .filter((tagFromSearch) => topTags.includes(tagFromSearch))
                    .reverse()
                setTagsFromSearch(shownTags)
            })
            .catch((err) => {
                error(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [topTags, showAutoComplete, debouncedSearchValue, setTagsFromSearch])

    useEffect(() => {
        setAutocompleteItems(
            tagsFromSearch.map((tag) => {
                return { displayName: `#${tag}`, value: tag }
            }),
        )
    }, [tagsFromSearch])

    const cleanSearchInput = (input: string) => {
        /**
         * Removes invalid characters that have been pasted into searchInput and bypassed cleaning in keydown handler
         * Allow characters that are: alphanumeric, -, #
         * */
        const cleanValue = input.replace(/[^a-zA-Z0-9#-]+/g, "")
        // Only allow # character to be at the beginning, remove otherwise.
        const noHashtagValue = cleanValue
            .replace(/#/g, "")
            .substring(0, MAX_INPUT_LENGTH)
        const prependChar = cleanValue[0] === "#" ? "#" : ""
        const correctedValue = `${prependChar}${noHashtagValue}`

        if (correctedValue !== tagSearchInput) {
            setTagSearchInput(correctedValue)
        }
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        const cleanNewChar = (e: React.KeyboardEvent): void => {
            // Prevent invalid characters from being typed into search input.

            // Allow # key if it's the 1st char in the search input and no other # key exists
            if (
                e.key === "#" &&
                searchInputElement.current?.selectionStart === 0 &&
                tagSearchInput.indexOf("#") === -1
            ) {
                return
            }
            // Allow hyphens
            if (e.key === "-") {
                return
            }
            // Prevent non-alphanumeric characters
            if (
                e.key.length === 1 &&
                !(e.key.match(/^[a-zA-Z0-9]$/)?.length === 1)
            ) {
                e.preventDefault()
            }
        }

        if (e.key === "Enter") {
            e.preventDefault()
            submitSearch()
        }
        cleanNewChar(e)
    }

    const submitSearch = () => {
        const searchWord = getSearchWord()

        if (searchWord !== "") {
            // Noop when tag is already selected

            if (selectedHashtag === searchWord) {
                return
            } else if (tagsFromSearch.includes(searchWord)) {
                applyTag(searchWord)
            }
        }
    }

    const applyTag = (hashtag: string) => {
        setTagSearchInput("")
        UrlState.current.setPartialState({ tags: [hashtag] })
        setShowAutoComplete(false)
    }

    const handleAutocompleteBlur = (e: MouseEvent) => {
        if (
            !autoCompleteRef.current?.contains(e.target as Node) &&
            !searchInputElement.current?.contains(e.target as Node)
        ) {
            setShowAutoComplete(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleAutocompleteBlur)
        return () => {
            document.removeEventListener("click", handleAutocompleteBlur)
        }
    }, [])

    return (
        <div className="FilterSearch">
            <div className="FilterSearch__input-container">
                <div
                    className="FilterSearch__Button"
                    data-testid="filter-tag-search-button"
                    onClick={() => {
                        submitSearch()
                    }}
                >
                    <Search />
                </div>
                <input
                    className="FilterSearch__Input"
                    data-testid="tag-search-input"
                    placeholder="Search #tags"
                    maxLength={MAX_INPUT_LENGTH}
                    value={tagSearchInput}
                    onChange={(e) => {
                        cleanSearchInput(e.target.value)
                    }}
                    onFocus={() => {
                        setShowAutoComplete(true)
                    }}
                    onKeyDown={handleKeyDown}
                    ref={searchInputElement}
                />
            </div>

            {showAutoComplete && (
                <div
                    className="FilterSearch__autocomplete-container"
                    ref={autoCompleteRef}
                >
                    <SearchAutocomplete
                        items={autocompleteItems}
                        onItemClick={applyTag}
                        loading={isLoading}
                        maxShownSuggestions={MAX_SHOWN_SUGGESTIONS}
                    />
                </div>
            )}
        </div>
    )
}

HashtagSearch.displayName = "HashtagSearch"
