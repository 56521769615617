// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H1b3MQ_18aezBDXcLfVv{display:flex;justify-content:space-between;align-items:center;padding:16px 0}.H1b3MQ_18aezBDXcLfVv span{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;color:#222;font-size:16px}.H1b3MQ_18aezBDXcLfVv:last-child{padding-bottom:0}.hiYcg_Nrl_mIoMzt9ELA{padding:12px 16px;background-color:#fff;margin-bottom:4px}.hiYcg_Nrl_mIoMzt9ELA h2{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;color:#b3b3b3;font-size:14px;margin-bottom:4px}.hiYcg_Nrl_mIoMzt9ELA p{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;color:#525252;font-size:14px;padding:0;margin:0 30px 0 0}.z20IPwi5KXFLspEPjQ9d{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;display:flex;padding:14px 16px;background-color:#fff;margin-bottom:4px;align-items:center}.z20IPwi5KXFLspEPjQ9d .WHkl5LzyJ_YlsfCyiFGq{height:36px;width:36px;border-radius:50%;margin-right:12px;background-color:gray}.z20IPwi5KXFLspEPjQ9d img{margin-left:auto;height:24px}.AYeqjaIktDs1oepYeWOa .InputToggle__switch{height:16px;width:32px}.AYeqjaIktDs1oepYeWOa .InputToggle__switch .InputToggle__slider:before{height:12px;width:12px;left:2px;bottom:2px}.AYeqjaIktDs1oepYeWOa .InputToggle__switch input:checked+.InputToggle__slider:before{transform:translateX(16px)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setting": `H1b3MQ_18aezBDXcLfVv`,
	"settingGroup": `hiYcg_Nrl_mIoMzt9ELA`,
	"messageProfile": `z20IPwi5KXFLspEPjQ9d`,
	"avatar": `WHkl5LzyJ_YlsfCyiFGq`,
	"toggle": `AYeqjaIktDs1oepYeWOa`
};
export default ___CSS_LOADER_EXPORT___;
