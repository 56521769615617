import type { ReactNode } from "react"
import type React from "react"
import { createContext, useContext, useEffect } from "react"
import { useAppContext } from "../../hooks/appContext"
import { useAppDispatch } from "../../store/hooks"
import { useTokenUpdateEffect } from "../../store/hooks/userHooks"
import {
    addMessageToHistory,
    messagingApi,
    upsertThread,
    useMarkAsReadMutation,
} from "../../store/messagingSlice"
import { store } from "../../store/store"
import type {
    DMUnreadData,
    Interfaces,
    Message,
    PushPrivateMessage,
    Thread,
    Topics,
    UserTopic,
} from "./types"

type MessagingContextType = {
    userMessageTopic: UserTopic<PushPrivateMessage>
    dmUnreadData: DMUnreadData
}

const MessagingContext = createContext<MessagingContextType | undefined>(
    undefined,
)

type ProviderProps = {
    children: ReactNode
    topics: Topics
    interfaces: Interfaces
}

const messageToThread = (message: PushPrivateMessage): Thread => {
    return {
        m: message.message,
        num_unread: 1,
        created_at: Math.floor(Date.now() / 1000), // match api format
        from_user: message.fromUser.username,
        other_user: {
            username: message.otherUsername,
            inFanclub: false,
            isMod: false,
            isBroadcaster: false,
            hasTokens: false,
            tippedRecently: false,
            tippedALotRecently: false,
            tippedTonsRecently: false,
            exploringHashTag: "",
            sourceName: "",
        },
    }
}

export const MessagingProvider: React.FC<ProviderProps> = ({
    children,
    topics,
    interfaces,
}) => {
    const { context: appContext } = useAppContext()
    const dispatch = useAppDispatch()
    const uuid = appContext.logged_in_user?.user_uid
    const userMessageTopic = new topics.UserMessageTopic(uuid || "")
    const [markAsRead] = useMarkAsReadMutation()

    const { UserTokenUpdateTopic } = topics
    useTokenUpdateEffect({
        userTokenUpdateTopic: UserTokenUpdateTopic,
        uuid,
    })

    useEffect(() => {
        if (
            userMessageTopic &&
            userMessageTopic.onMessage.listenerCount() === 0
        ) {
            userMessageTopic.onMessage.listen((m: PushPrivateMessage) => {
                const thread = messageToThread(m)
                const isFromMe =
                    m.fromUser.username === appContext.logged_in_user?.username

                dispatch(upsertThread(thread, isFromMe))

                const otherUsername =
                    m.otherUsername === appContext.logged_in_user?.username
                        ? m.fromUser.username
                        : m.otherUsername

                if (!isFromMe) {
                    interfaces.dmUnreadData.addUnreadRecipient(otherUsername)
                }

                const cacheState = messagingApi.endpoints.getHistory.select({
                    username: otherUsername,
                })(store.getState())

                if (cacheState.data) {
                    const newMessage: Message = {
                        i: m.messageID,
                        m: m.message,
                        created_at:
                            (m.createdAt?.getTime() || Date.now()) / 1000,
                        media: [],
                        other_user: m.otherUsername,
                        from_user: m.fromUser,
                    }
                    dispatch(addMessageToHistory(otherUsername, newMessage))
                }
            })
        }
    }, [
        dispatch,
        userMessageTopic,
        appContext.logged_in_user?.username,
        markAsRead,
    ])

    return (
        <MessagingContext.Provider
            value={{
                userMessageTopic,
                dmUnreadData: interfaces.dmUnreadData,
            }}
        >
            {children}
        </MessagingContext.Provider>
    )
}

export const useMessagingContext = (): MessagingContextType => {
    const context = useContext(MessagingContext)
    if (context === undefined) {
        throw new Error(
            "useMessagingContext must be used within a MessagingProvider",
        )
    }
    return context
}
