import React, { useLayoutEffect, useRef } from "react"
import { Spinner } from "../../common/atoms/Spinner/Spinner"
import { Message as MessageComponent } from "../Message/Message"
import { MessageTyping } from "../MessageTyping/MessageTyping"
import { getTimeStamp, showAvatar } from "./utils"
import type { Message } from "../types"
import "./MessageRow.scss"

const TEST_IS_TYPING = false
export interface MessageRowData {
    messages: Message[]
    showMenuMessageId: string | null
    setShowMenuMessageId: (id: string | null) => void
    username: string
    onHeightChange: (index: number, height: number, messageId: string) => void
    isAllHistoryLoaded: boolean
    isLoadingOlderMessages: boolean
}

interface MessageRowProps {
    index: number
    style: React.CSSProperties
    data: MessageRowData
}

const useMessageHeight = (
    rowRef: React.RefObject<HTMLDivElement>,
    messageIndex: number,
    message: Message | null,
    onHeightChange: (index: number, height: number, messageId: string) => void,
) => {
    useLayoutEffect(() => {
        if (!rowRef.current || !message) return
        onHeightChange(messageIndex, rowRef.current.clientHeight, message.i)
    }, [messageIndex, message?.m, onHeightChange])
}

export const MessageRow = React.memo(
    ({ index, style, data }: MessageRowProps) => {
        const rowRef = useRef<HTMLDivElement>(null)
        const {
            messages,
            showMenuMessageId,
            setShowMenuMessageId,
            username,
            onHeightChange,
            isAllHistoryLoaded,
            isLoadingOlderMessages,
        } = data

        const messageIndex = index - 1
        const message =
            messageIndex >= 0 && messageIndex < messages.length
                ? messages[messageIndex]
                : null

        useMessageHeight(rowRef, messageIndex, message, onHeightChange)

        if (index === 0) {
            return (
                <div
                    style={style}
                    className={`messages-loader ${isLoadingOlderMessages && !isAllHistoryLoaded ? "visible" : ""}`}
                >
                    <Spinner size="xspx" />
                </div>
            )
        }

        if (!message || messageIndex >= messages.length) return null

        const previousMessage =
            messageIndex > 0 ? messages[messageIndex - 1] : null
        const nextMessage =
            messageIndex < messages.length - 1
                ? messages[messageIndex + 1]
                : undefined

        const timestamp = getTimeStamp(
            message,
            previousMessage,
            isAllHistoryLoaded,
        )

        return (
            <div ref={rowRef} style={style} className="message-row">
                {timestamp && (
                    <div className="message-timestamp-container">
                        {timestamp}
                    </div>
                )}
                {TEST_IS_TYPING && messageIndex === messages.length - 1 ? (
                    <MessageTyping />
                ) : (
                    <MessageComponent
                        message={message}
                        showMenuMessageId={showMenuMessageId}
                        setShowMenuMessageId={setShowMenuMessageId}
                        showAvatar={showAvatar(username, message, nextMessage)}
                    />
                )}
            </div>
        )
    },
)
