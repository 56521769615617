// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BfH2vCs3VZU2R9cMdRH4{display:flex;margin-left:20px}.BfH2vCs3VZU2R9cMdRH4 .I7pUk8rJY985rTvSnk1v{align-self:flex-end;height:24px;width:24px;border-radius:50%;margin-right:2px;background:#e0e0e0}.BfH2vCs3VZU2R9cMdRH4 .BR2lN4s2YZ3Tabq8XRtR{display:flex;gap:6px;align-items:center;justify-content:center;width:68px;height:42px;background-color:#e0e0e0;border-radius:8px 16px 16px 8px;position:relative}.BfH2vCs3VZU2R9cMdRH4 .BR2lN4s2YZ3Tabq8XRtR .S4BcWCPCHjtnnRxqCaLq{width:8px;height:8px;background-color:#6e6f70;border-radius:50%;animation:IaRbzEogePcVrD7nqLa4 1.2s infinite ease-in-out}.BfH2vCs3VZU2R9cMdRH4 .BR2lN4s2YZ3Tabq8XRtR .S4BcWCPCHjtnnRxqCaLq:nth-child(2){animation-delay:.2s}.BfH2vCs3VZU2R9cMdRH4 .BR2lN4s2YZ3Tabq8XRtR .S4BcWCPCHjtnnRxqCaLq:nth-child(3){animation-delay:.4s}@keyframes IaRbzEogePcVrD7nqLa4{0%,80%,100%{transform:translateY(0)}40%{transform:translateY(-10px)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BfH2vCs3VZU2R9cMdRH4`,
	"avatar": `I7pUk8rJY985rTvSnk1v`,
	"typingIndicator": `BR2lN4s2YZ3Tabq8XRtR`,
	"bubble": `S4BcWCPCHjtnnRxqCaLq`,
	"bounce": `IaRbzEogePcVrD7nqLa4`
};
export default ___CSS_LOADER_EXPORT___;
