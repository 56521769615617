import type React from "react"
import { useState } from "react"
import { classNames } from "../../utils"
import styles from "./Textarea.module.scss"

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string
}

export const Textarea: React.FC<Props> = ({ label, maxLength, ...props }) => {
    const [value, setValue] = useState("")
    const [isFocused, setIsFocused] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value)
        if (props.onChange) {
            props.onChange(e)
        }
    }

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    return (
        <div className={styles.textareaWrapper}>
            {label && <label className={styles.label}>{label}</label>}
            <textarea
                {...props}
                className={classNames(styles.textarea, props.className, {
                    [styles.hasCounter]: Boolean(maxLength && isFocused),
                })}
                maxLength={maxLength}
                value={value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {maxLength && isFocused && (
                <div className={styles.counter}>
                    {value.length}/{maxLength}
                </div>
            )}
        </div>
    )
}
