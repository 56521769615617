import { useEffect, useState } from "react"
import { Plural, Trans } from "@lingui/macro"
import { gettext, interpolate, ngettext } from "@multimediallc/web-utils"
import { featureFlagIsActive } from "../../../../hooks/appContext"
import { colorClass } from "../../../../utils/css"
import { UserList } from "../UserList/UserList"
import type { IUserInfo } from "../../../../interfaces/userMenu"
import "./UserListContent.scss"

// Since the parent is in TS and isn't a React component, we cannot utilize React hooks
// like forwardRef and useImperativeHandle to mimic public class methods. Therefore,
// here we use useEffect, which in turn require props (with names prefixed with "id")
// that we deliberately reassign the value of, to trigger the desired effect.
// ** idShowUsers: hides loading UI, even when newly fetched props.roomUsers doesn't change
// ** idShowLoading: shows loading UI
type UserListContentProps = {
    roomUsers: IUserInfo[]
    createContextMenu: (user: IUserInfo, element: HTMLElement) => void
    refreshFunc: () => void
    anonCount: number
    idShowUsers?: number
    idShowLoading?: number
}

export const i18n = {
    loadingUserListText: gettext("Loading user list..."),
    refreshUserListText: gettext("refresh userlist"),
    anonUsersCount: (anonCount: number): string => {
        return interpolate(
            ngettext(
                "+%(anonCount)s anonymous user",
                "+%(anonCount)s anonymous users",
                anonCount,
            ),
            { anonCount: anonCount },
            true,
        )
    },
}

export function UserListContent(props: UserListContentProps) {
    const linguiBasic = featureFlagIsActive("LinguiBasic")
    const linguiMigrate = featureFlagIsActive("LinguiMigrate")
    const [loading, setLoading] = useState(true)

    // Show loading UI
    useEffect(() => {
        setLoading(true)
    }, [props.idShowLoading])

    // Hide loading UI once data has been fetched
    useEffect(() => {
        setLoading(false)
    }, [props.roomUsers, props.idShowUsers])

    return (
        <div className={`UserListContent ${colorClass.defaultColor}`}>
            <div className={`loadingDiv ${!loading ? "hidden" : ""}`}>
                {linguiBasic ? (
                    <Trans>Loading user list...</Trans>
                ) : (
                    i18n.loadingUserListText
                )}
            </div>
            <div className={`contentDiv ${loading ? "hidden" : ""}`}>
                <div className="refreshDiv">
                    <span
                        className="refreshLink"
                        onClick={() => {
                            setLoading(true)
                            props.refreshFunc()
                        }}
                    >
                        {linguiBasic ? (
                            <Trans>refresh userlist</Trans>
                        ) : (
                            i18n.refreshUserListText
                        )}
                    </span>
                </div>
                <div className="userListDiv">
                    <UserList
                        roomUsers={props.roomUsers}
                        createContextMenu={props.createContextMenu}
                    />
                </div>
                <div
                    className={`anonUserCount ${colorClass.defaultTooltipColor}`}
                >
                    {linguiMigrate ? (
                        <Plural
                            value={props.anonCount}
                            one="+# anonymous user"
                            other="+# anonymous users"
                        />
                    ) : (
                        i18n.anonUsersCount(props.anonCount)
                    )}
                </div>
            </div>
        </div>
    )
}
