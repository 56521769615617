import React from "react"
import { t, Trans } from "@lingui/macro"
import "./NoSearchResultsMessage.scss"
import {
    getCategorySlug,
    HOMEPAGE_KEYS,
    isOfflineFollowed,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import {
    UrlStateContextProvider,
    useUrlStateContext,
} from "../../../hooks/urlStateContext"
import { getKeywordString, getRoomlistHeaderText } from "../categoryHeaderUtils"

export const enum UsageType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SHARED = "shared",
}

interface SearchResultMessageProps {
    appName?: string
    usageType: UsageType
    matchedCount?: number
    totalCount?: number
    path?: string
}

export function NoSearchResultsMessage(props: SearchResultMessageProps) {
    return (
        <UrlStateContextProvider>
            <NoSearchResultsMessageContents {...props} />
        </UrlStateContextProvider>
    )
}

export function NoSearchResultsMessageContents(
    props: SearchResultMessageProps,
) {
    const { keywords, showType, apps, aspApp } = useUrlStateContext()

    const isPrivateCamsPage = showType === ShowType.PRIVATE

    const clearFiltersSansShowType = (e: React.MouseEvent) => {
        e.preventDefault()
        UrlState.current.navigateTo((e.currentTarget as HTMLAnchorElement).href)
    }

    const getSuggestions = () => {
        return keywordString === "" ? (
            <Trans>
                <li>Remove some of the filter options</li>
                <li>Try different filters</li>
                <li>Clear all filters</li>
            </Trans>
        ) : (
            <Trans>
                <li>Make sure words are spelled correctly</li>
                <li>Try more general key words</li>
                <li>Try different filters or search terms</li>
            </Trans>
        )
    }

    const keywordString = getKeywordString(keywords)

    const clearFilters = (e: React.MouseEvent): void => {
        e.preventDefault()
        UrlState.current.clearStateKeys(HOMEPAGE_KEYS)
    }

    const getSearchResultActions = () => {
        if (keywordString !== "") {
            const quotedKeywordString = `'${keywordString}'`
            if (isPrivateCamsPage) {
                if (props.usageType === UsageType.SHARED) {
                    return (
                        <Trans>
                            No search results for <b>{quotedKeywordString}</b>{" "}
                            in{" "}
                            <a
                                href="/spy-on-cams/"
                                onClick={clearFiltersSansShowType}
                            >
                                Private Shows
                            </a>{" "}
                            and{" "}
                            <a
                                href="/hidden-cams/"
                                onClick={clearFiltersSansShowType}
                            >
                                Community Controlled Shows
                            </a>
                            , please update your search/filter options, or{" "}
                            <a href="/" onClick={clearFilters}>
                                go back to the home page
                            </a>
                            .
                        </Trans>
                    )
                } else if (props.usageType === UsageType.PRIMARY) {
                    return (
                        <Trans>
                            No search results for <b>{quotedKeywordString}</b>{" "}
                            in Private Shows, please update your search/filter
                            options.
                        </Trans>
                    )
                } else {
                    return (
                        <Trans>
                            No search results for <b>{quotedKeywordString}</b>{" "}
                            in Community Controlled Shows, please update your
                            search/filter options.
                        </Trans>
                    )
                }
            } else if (
                (apps !== undefined || aspApp !== undefined) &&
                props.appName !== undefined
            ) {
                return (
                    <Trans>
                        No search results for <b>{quotedKeywordString}</b> for
                        users running {props.appName}, please update your
                        search/filter options, or{" "}
                        <a href="/" onClick={clearFilters}>
                            go back to the home page
                        </a>
                        .
                    </Trans>
                )
            } else {
                const categoryHeader =
                    getRoomlistHeaderText(getCategorySlug(props.path)) ?? ""
                if (categoryHeader) {
                    return (
                        <Trans>
                            No search results for <b>{quotedKeywordString}</b>{" "}
                            in {categoryHeader}, please update your
                            search/filter options, or{" "}
                            <a href="/" onClick={clearFilters}>
                                go back to the home page
                            </a>
                            .
                        </Trans>
                    )
                } else {
                    return (
                        <Trans>
                            No search results for <b>{quotedKeywordString}</b>,
                            please update your search/filter options, or{" "}
                            <a href="/" onClick={clearFilters}>
                                go back to the home page
                            </a>
                            .
                        </Trans>
                    )
                }
            }
        } else if (isPrivateCamsPage) {
            if (props.usageType === UsageType.SHARED) {
                return (
                    <Trans>
                        None of the {props.totalCount ?? 0} live rooms matched
                        your filters in{" "}
                        <a
                            href="/spy-on-cams/"
                            onClick={clearFiltersSansShowType}
                        >
                            Private Shows
                        </a>{" "}
                        and{" "}
                        <a
                            href="/hidden-cams/"
                            onClick={clearFiltersSansShowType}
                        >
                            Community Controlled Shows
                        </a>
                        , please update your search/filter options.
                    </Trans>
                )
            } else if (props.usageType === UsageType.PRIMARY) {
                return (
                    <Trans>
                        None of the {props.totalCount ?? 0} live rooms matched
                        your filters in Private Shows, please update your
                        search/filter options.
                    </Trans>
                )
            } else {
                return (
                    <Trans>
                        None of the {props.totalCount ?? 0} live rooms matched
                        your filters in Community Controlled Shows, please
                        update your search/filter options.
                    </Trans>
                )
            }
        } else {
            return (
                <Trans>
                    None of the {props.totalCount ?? 0} live rooms matched your
                    filters, please update your search/filter options.
                </Trans>
            )
        }
    }

    const isOfflineFollowedPage = isOfflineFollowed()

    return (
        <div
            className={`NoSearchResultsMessage${props.matchedCount === 0 ? "" : " NoSearchResultsMessage__hidden"}`}
        >
            <div data-testid="no-results-messsage">
                <b>
                    <Trans>No Results Found</Trans>
                </b>
                {!isOfflineFollowedPage && (
                    <React.Fragment>
                        {" "}
                        - {getSearchResultActions()}
                    </React.Fragment>
                )}
            </div>
            {!isOfflineFollowedPage && (
                <div
                    className="NoSearchResultsMessage__suggestions"
                    data-testid="suggestions"
                >
                    <div className="NoSearchResultsMessage__suggestions-header">{t`Suggestions:`}</div>
                    <ul className="NoSearchResultsMessage__suggestions-list">
                        {getSuggestions()}
                    </ul>
                </div>
            )}
        </div>
    )
}
