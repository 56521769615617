// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eDRq12lW5IQc90ffJZxh{display:flex;padding:12px 22px;background-color:#fff9f7;border-top:1px solid rgba(251,199,165,.4784313725);position:relative}.eDRq12lW5IQc90ffJZxh::before{content:"";position:absolute;top:-8px;left:24px;height:15px;width:15px;transform:rotate(45deg);border-top:1px solid rgba(251,199,165,.4784313725);border-left:1px solid rgba(251,199,165,.4784313725);background-color:#fff9f7}.eDRq12lW5IQc90ffJZxh .J6OJoRZoXJKVc7L7nXIg{align-self:flex-start;margin-right:10px;z-index:1}.eDRq12lW5IQc90ffJZxh .Kigr6ysL2nxZnlZcDtDy{margin-right:16px}.eDRq12lW5IQc90ffJZxh .Kigr6ysL2nxZnlZcDtDy h2{font-family:"UbuntuBold",Arial,Helvetica,sans-serif;color:#f47321;margin:0 0 7px 0;font-size:16px}.eDRq12lW5IQc90ffJZxh .Kigr6ysL2nxZnlZcDtDy p{color:#f47321;font-size:12px;margin:0}.eDRq12lW5IQc90ffJZxh .SVyUN3ZZDLyMQZzyINYw{height:24px;width:24px;margin:-4px 4px 0 0}.eDRq12lW5IQc90ffJZxh .SVyUN3ZZDLyMQZzyINYw img{height:14px;width:14px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageProfileAlert": `eDRq12lW5IQc90ffJZxh`,
	"setupIcon": `J6OJoRZoXJKVc7L7nXIg`,
	"message": `Kigr6ysL2nxZnlZcDtDy`,
	"closeIcon": `SVyUN3ZZDLyMQZzyINYw`
};
export default ___CSS_LOADER_EXPORT___;
