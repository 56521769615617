import { getCurrentGender } from "@multimediallc/gender-utils"
import { SPOKEN_LANGUAGES_MAP } from "@multimediallc/web-utils"
import { generateApiPath } from "../../../routes/util"
import { fetchGet } from "../../../utils/myFetch"

export interface ILanguage {
    code: string
    display_name: string
}

export interface ISearchLanguagesResponse {
    languages: string[]
}

export function searchSpokenLanguages(params: {
    search?: string
    sort?: "room_count"
}): Promise<ILanguage[]> {
    const queryParams = new URLSearchParams({
        ...params,
        genders: getCurrentGender(),
    })
    const apiPath = `${generateApiPath("en", "roomlist", "spoken-languages-search/")}?${queryParams.toString()}`
    return fetchGet(apiPath).then((resp) => {
        const languageCodes = (resp.jsonData as ISearchLanguagesResponse)
            .languages
        return languageCodes.reverse().map((langCode) => {
            return {
                code: langCode,
                display_name: SPOKEN_LANGUAGES_MAP.get(langCode) ?? "",
            }
        })
    })
}

export const MAX_NUM_LANGUAGES = 5
export const MAX_INPUT_LENGTH = 24
