import type React from "react"
import { useEffect, useRef, useState } from "react"
import { HOMEPAGE_KEYS, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { SPOKEN_LANGUAGES_MAP } from "@multimediallc/web-utils"
import { MAX_NUM_LANGUAGES } from "../LanguageFilterSection/languageUtils"
import type { ILanguage } from "../LanguageFilterSection"
import "./LanguageFilterSectionOptions.scss"

interface LanguageFilterSectionOptionsProps {
    /** The top 5 language options with the highest amount of broadcaster
     that will show as clickable filter */
    options: ILanguage[]
    /** The current selected language, as language code */
    selectedLanguage: string
    /** selects the given language */
    onLanguageClick: (value: string) => void
    /** helper for getting the correct href for a given option */
    getToggledHref: (language: string) => string
}

export function LanguageFilterSectionOptions({
    options,
    selectedLanguage,
    onLanguageClick,
    getToggledHref,
}: LanguageFilterSectionOptionsProps) {
    const ref = useRef<HTMLDivElement>(null)
    const [, setFullPath] = useState<string>("")
    const handleClick = (
        event: React.MouseEvent<HTMLAnchorElement>,
        languageCode: string,
    ): void => {
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
            return
        }
        event.preventDefault()
        onLanguageClick(languageCode)
    }

    useEffect(() => {
        if (ref.current === null) {
            return
        }
        UrlState.current.listen(
            HOMEPAGE_KEYS,
            () =>
                // Update state to force a re-render when path/search change, in order to keep href's up-to-date.
                setFullPath(
                    `${window.location.pathname}${window.location.search}`,
                ),
            ref.current,
        )
    }, [ref])

    const finalOptions = options.slice(0, MAX_NUM_LANGUAGES)
    const languageInOptions = finalOptions.find(
        (language) => language.code === selectedLanguage,
    )
    const displayName = SPOKEN_LANGUAGES_MAP.get(selectedLanguage)
    if (languageInOptions === undefined && displayName !== undefined) {
        finalOptions.unshift({
            code: selectedLanguage,
            display_name: displayName,
        })
    }
    return (
        <div ref={ref} className="LanguageFilterSectionOptions">
            {finalOptions.length > 0 &&
                finalOptions.map((option) => (
                    <a
                        key={option.code}
                        data-testid={`language-option-${option.code}`}
                        href={getToggledHref(option.code)}
                        className={`filterOption ${selectedLanguage === option.code ? "active" : ""}`}
                        onClick={(e) => {
                            handleClick(e, option.code)
                        }}
                    >
                        {option.display_name}
                    </a>
                ))}
        </div>
    )
}
