import type { ComponentPropsWithoutRef } from "react"
import "./StylelessButton.scss"

// Useful for cases like icon buttons where we don't want the default button background, outline, padding, etc
export function StylelessButton({
    children,
    ...props
}: ComponentPropsWithoutRef<"button">) {
    return (
        <button {...props} className="StylelessButton">
            {children}
        </button>
    )
}
