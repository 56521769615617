import { type ComponentPropsWithoutRef, type SyntheticEvent } from "react"
import "./DirectiveTooltip.scss"
import { CloseWhite } from "../../common/atoms/Icons/Others"

interface DirectiveTooltipProps extends ComponentPropsWithoutRef<"div"> {
    text: string
    position: directivePosition
    closeCallback: () => void
}

export enum directivePosition {
    left = "left",
    right = "right",
    center = "center",
    xleft = "xleft",
    xright = "xright",
}

export function DirectiveTooltip({
    text,
    position = directivePosition.center,
    closeCallback,
    ...props
}: DirectiveTooltipProps) {
    const close = (e: React.MouseEvent) => {
        e.preventDefault()
        closeCallback()
    }
    const absorb = (e: SyntheticEvent) => {
        e.stopPropagation()
    }
    return (
        <div {...props} className="DirectiveTooltip">
            <div className="tooltipDiv" onClick={absorb}>
                <div className="tooltipText">{text}</div>
                <div onClick={close} className="tooltipClose">
                    <CloseWhite />
                </div>
            </div>
            <svg
                className={`tooltipArrow ${position}`}
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="9"
                viewBox="0 0 23 9"
                fill="none"
            >
                <path
                    d="M12.3661 8.50084C12.001 8.79271 11.4824 8.79271 11.1173 8.50084L0.483338 -1.96847e-06L23 0L12.3661 8.50084Z"
                    fill="#0B0907"
                />
            </svg>
        </div>
    )
}
