import type { ButtonHTMLAttributes } from "react"
import "./IconButton.scss"
import { classNames } from "../../utils"

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    icon: string
    iconAlt: string
    className?: string
}

export function IconButton({ icon, iconAlt, className, ...props }: Props) {
    return (
        <button className={classNames("msg-icon-button", className)} {...props}>
            <img src={icon} alt={iconAlt} />
        </button>
    )
}
