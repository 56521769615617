import "./CombinedOverlay.scss"
import "../filterSections/filterSection.scss"
import type React from "react"
import type { ReactNode } from "react"
import {
    DEFAULT_MAX_AGE,
    DEFAULT_MIN_AGE,
    isLanguageFilterActive,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../hooks/urlStateContext"
import { useFiltersContext } from "../FiltersContext"
import { AgeSectionContent } from "../filterSections/AgeSection/AgeSection"
import { HashtagSectionContent } from "../filterSections/hashtagSection/HashtagSection"
import { LanguageSectionContent } from "../filterSections/languageSection/LanguageSection/"
import { OtherSection } from "../filterSections/OtherSection/OtherSection"
import { PrivatePriceSectionContent } from "../filterSections/PrivatePriceSection/PrivatePriceSection"
import { RegionSectionContent } from "../filterSections/RegionSection/RegionSection"
import { RoomSizeSectionContent } from "../filterSections/RoomSizeSection/RoomSizeSection"
import { SpyPriceSectionContent } from "../filterSections/SpyPriceSection/SpyPriceSection"
import { ActiveFiltersSection } from "../types"
import type { EventRouter } from "../../../../messaging/types"

interface CombinedOverlayProps {
    showLocationRouter: EventRouter<boolean>
    setThumbnailAnimations: (animate: boolean) => void
    reloadRooms: (onSuccess: () => void) => void
}

export const CombinedOverlay = ({
    showLocationRouter,
    setThumbnailAnimations,
    reloadRooms,
}: CombinedOverlayProps) => {
    const { setOverlayedSection, setTagSearchInput, setLanguageSearchInput } =
        useFiltersContext()
    const urlStateContext = useUrlStateContext()
    const showPrivatePriceFilters =
        urlStateContext.showType !== ShowType.PRIVATE
    const privatePriceFilterCount = (urlStateContext.privatePrices ?? []).length
    const spyPriceFilterCount = (urlStateContext.spyShowPrices ?? []).length

    const showLanguageFilters = isLanguageFilterActive()

    const isDefaultAgeSelection = () => {
        return (
            (urlStateContext.ageMin === undefined ||
                urlStateContext.ageMin === DEFAULT_MIN_AGE) &&
            (urlStateContext.ageMax === undefined ||
                urlStateContext.ageMax === DEFAULT_MAX_AGE)
        )
    }

    const onClear = () => {
        if (nSelections === 0) {
            return
        }
        UrlState.current.setPartialState({
            regions: undefined,
            roomSize: undefined,
            privatePrices: undefined,
            tags: undefined,
            ageMin: DEFAULT_MIN_AGE,
            ageMax: DEFAULT_MAX_AGE,
            spokenLanguages: undefined,
        })
        setTagSearchInput("")
        setLanguageSearchInput("")
    }

    const calculateSelections = () => {
        const languageFilterCount = isLanguageFilterActive()
            ? (urlStateContext.spokenLanguages ?? []).length
                ? 1
                : 0
            : 0
        return (
            (urlStateContext.regions ?? []).length +
            (urlStateContext.roomSize === undefined ? 0 : 1) +
            privatePriceFilterCount +
            ((urlStateContext.tags ?? []).length ? 1 : 0) +
            (isDefaultAgeSelection() ? 0 : 1) +
            languageFilterCount
        )
    }

    const nSelections = calculateSelections()

    return (
        <div data-testid="mobile-filters-combined-overlay">
            <div className="FilterSection__ScrollableContent noScrollbar">
                <FilterSectionCombined
                    title="Tags"
                    count={(urlStateContext.tags ?? []).length ? 1 : 0}
                >
                    <HashtagSectionContent combinedOverlay={true} />
                </FilterSectionCombined>
                <FilterSectionCombined
                    title="Regions"
                    count={(urlStateContext.regions ?? []).length}
                >
                    <RegionSectionContent combinedOverlay={true} />
                </FilterSectionCombined>
                <FilterSectionCombined
                    title="Room Size"
                    count={urlStateContext.roomSize === undefined ? 0 : 1}
                >
                    <RoomSizeSectionContent combinedOverlay={true} />
                </FilterSectionCombined>
                {showPrivatePriceFilters && (
                    <FilterSectionCombined
                        title="Private Show Tokens Per Minute"
                        count={privatePriceFilterCount}
                    >
                        <PrivatePriceSectionContent combinedOverlay={true} />
                    </FilterSectionCombined>
                )}
                {!showPrivatePriceFilters && (
                    <FilterSectionCombined
                        title="Spy Show Tokens Per Minute"
                        count={spyPriceFilterCount}
                    >
                        <SpyPriceSectionContent combinedOverlay={true} />
                    </FilterSectionCombined>
                )}
                <FilterSectionCombined
                    title="Ages"
                    count={isDefaultAgeSelection() ? 0 : 1}
                    onClear={() => {
                        if (!isDefaultAgeSelection()) {
                            UrlState.current.setPartialState({
                                ageMin: DEFAULT_MIN_AGE,
                                ageMax: DEFAULT_MAX_AGE,
                            })
                        }
                    }}
                    isClearDisabled={isDefaultAgeSelection()}
                >
                    <AgeSectionContent />
                </FilterSectionCombined>
                {showLanguageFilters && (
                    <FilterSectionCombined
                        title="Languages"
                        count={
                            (urlStateContext.spokenLanguages ?? []).length
                                ? 1
                                : 0
                        }
                    >
                        <LanguageSectionContent combinedOverlay={true} />
                    </FilterSectionCombined>
                )}
                <FilterSectionCombined
                    title="Options"
                    count={0}
                    isClearDisabled={true}
                >
                    <OtherSection
                        setThumbnailAnimations={setThumbnailAnimations}
                        showLocationRouter={showLocationRouter}
                        reloadRooms={reloadRooms}
                    />
                </FilterSectionCombined>
            </div>

            <div className="FilterSection__footer">
                <button
                    onClick={onClear}
                    className={`FilterSection__clear-button${
                        nSelections === 0
                            ? " FilterSection__clear-button-disabled"
                            : ""
                    }`}
                >
                    Clear all
                    {`${nSelections === 0 ? "" : ` (${nSelections})`}`}
                </button>
                <button
                    onClick={() =>
                        setOverlayedSection(ActiveFiltersSection.None)
                    }
                    className={`FilterSection__combined-apply${
                        nSelections === 0
                            ? " FilterSection__combined-apply-disabled"
                            : ""
                    }`}
                >
                    Show Results
                </button>
            </div>
        </div>
    )
}

interface FilterSectionCombinedProps {
    title: string
    count?: number
    onClear?: () => void
    children: ReactNode
    isClearDisabled?: boolean
}

const FilterSectionCombined: React.FC<FilterSectionCombinedProps> = ({
    title,
    count = 0,
    onClear,
    children,
    isClearDisabled = false,
}: FilterSectionCombinedProps) => {
    const getTestId = (title: string) => {
        return `mobile-filters-${title.toLowerCase().replaceAll(" ", "-")}-subsection`
    }
    return (
        <div className="FilterSection__section" data-testid={getTestId(title)}>
            <div className="FilterSection__header">
                <span className="FilterSection__header-text">
                    {title}
                    {count > 0 ? ` (${count})` : ""}
                </span>
                {onClear && (
                    <button
                        className={`FilterSection__clear-button${
                            isClearDisabled
                                ? " FilterSection__clear-button-disabled"
                                : ""
                        }`}
                        onClick={onClear}
                        disabled={isClearDisabled}
                    >
                        reset
                    </button>
                )}
            </div>
            {children}
        </div>
    )
}
