// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QlqfIEiHdZhYtYGsHPr7{position:relative;display:flex;flex-direction:column}.Uu8fUcsH6iBjDAV63I05{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;margin-bottom:8px}.aUVjwdljr1KzGcTknIbQ{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;background-color:#f8f9fa;resize:vertical;padding:11px;border-radius:8px;height:84px;border:none;font-size:14px}.aUVjwdljr1KzGcTknIbQ._2sPCkYXOeXnYs9rigG7w{padding-bottom:24px}.aUVjwdljr1KzGcTknIbQ::placeholder{color:#b3b3b3}.qXABMiNyJjYdssDCuxCA{position:absolute;bottom:24px;right:7px;color:#b3b3b3;background-color:#f8f9fa}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textareaWrapper": `QlqfIEiHdZhYtYGsHPr7`,
	"label": `Uu8fUcsH6iBjDAV63I05`,
	"textarea": `aUVjwdljr1KzGcTknIbQ`,
	"hasCounter": `_2sPCkYXOeXnYs9rigG7w`,
	"counter": `qXABMiNyJjYdssDCuxCA`
};
export default ___CSS_LOADER_EXPORT___;
