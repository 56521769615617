import { useEffect, useRef, useState } from "react"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { gettext } from "@multimediallc/web-utils"
import { error } from "../../../utils/debug"
import { LanguageFilterSearchInput } from "../LanguageFilterSearchInput"
import { LanguageFilterSectionOptions } from "../LanguageFilterSectionOptions"
import { searchSpokenLanguages } from "./languageUtils"
import type { ILanguage } from "./languageUtils"

const i18n = {
    languageSectionHeader: gettext("LANGUAGES"),
}

interface LanguageFilterSectionProps {
    onFilterOptionClick: (selectedLanguage: string) => void
    getToggledHref: (language: string) => string
}

export function LanguageFilterSection({
    onFilterOptionClick,
    getToggledHref,
}: LanguageFilterSectionProps) {
    const [selectedLanguage, setSelectedLanguage] = useState<string>("")
    const [languageSectionOptions, setLanguageSectionOptions] = useState<
        ILanguage[]
    >([])
    const ref = useRef<HTMLDivElement>(null)

    const updateLanguageOptions = () => {
        searchSpokenLanguages({ sort: "room_count" })
            .then((languages) => {
                setLanguageSectionOptions(languages)
            })
            .catch((e) => error(e))
    }

    useEffect(updateLanguageOptions, [])

    useEffect(() => {
        setSelectedLanguage(UrlState.current.state.spokenLanguages?.[0] ?? "")
    }, [])

    useEffect(() => {
        if (ref.current === null) {
            return
        }
        UrlState.current.listen(
            ["spokenLanguages"],
            (state) => {
                setSelectedLanguage(state.spokenLanguages?.[0] ?? "")
            },
            ref.current,
        )
        UrlState.current.listen(["genders"], updateLanguageOptions, ref.current)
    }, [ref])

    const handleLanguageClick = (language: string) => {
        onFilterOptionClick(language)
        const newLanguageSelection =
            selectedLanguage !== language ? language : ""
        setSelectedLanguage(newLanguageSelection)
    }

    const handleLanguageClickNoToggle = (language: string) => {
        if (language !== selectedLanguage) {
            handleLanguageClick(language)
        }
    }

    return (
        <div ref={ref} className="LanguageFilterSection filterSection">
            <div
                className="filterSectionHeader"
                data-testid="language-filter-header"
            >
                {i18n.languageSectionHeader}
            </div>
            <LanguageFilterSearchInput
                onLanguageClick={handleLanguageClickNoToggle}
            />
            <LanguageFilterSectionOptions
                options={languageSectionOptions}
                selectedLanguage={selectedLanguage}
                onLanguageClick={handleLanguageClick}
                getToggledHref={getToggledHref}
            />
        </div>
    )
}
