import "../filterSection.scss"
import { Region, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../../hooks/urlStateContext"
import { RegionToTitle } from "../../types"
import { FilterSection } from "../FilterSection"

export const RegionSection = () => {
    const urlStateContext = useUrlStateContext()
    const isClearDisabled =
        urlStateContext.regions === undefined ||
        urlStateContext.regions.length === 0

    return (
        <FilterSection
            isClearDisabled={isClearDisabled}
            title="Regions"
            onClear={() => {
                if ((urlStateContext.regions ?? []).length === 0) {
                    return
                }
                UrlState.current.setPartialState({ regions: undefined })
            }}
            clearButtonText="Clear all"
        >
            <RegionSectionContent />
        </FilterSection>
    )
}

export const RegionSectionContent = ({
    combinedOverlay = false,
}: {
    combinedOverlay?: boolean | undefined
}) => {
    const urlStateContext = useUrlStateContext()
    const handleRegionTouch = (region: Region) => {
        let regions: Region[]
        if (urlStateContext.regions?.includes(region)) {
            regions = urlStateContext.regions.filter((r) => r !== region)
        } else {
            regions = [...(urlStateContext.regions ?? []), region]
        }
        UrlState.current.setPartialState({ regions: regions })
    }

    return (
        <div
            className={`FilterSection__filterOptions${combinedOverlay ? "Combined" : ""}`}
        >
            {Object.values(Region).map((region) => (
                <button
                    key={region}
                    data-testid={`region-filter-option-${RegionToTitle.get(region) as string}`}
                    className={`FilterSection__filterOption ${
                        urlStateContext.regions?.includes(region)
                            ? "active"
                            : ""
                    }`}
                    onClick={() => handleRegionTouch(region)}
                >
                    {RegionToTitle.get(region) as string}
                </button>
            ))}
        </div>
    )
}
