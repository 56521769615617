import "../filterSection.scss"
import { RoomSize, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../../hooks/urlStateContext"
import { FilterSection } from "../FilterSection"

export const ROOM_SIZE_MAPPING = new Map<RoomSize, string>([
    [RoomSize.SMALL, "Intimate"],
    [RoomSize.MEDIUM, "Mid-Sized"],
    [RoomSize.LARGE, "High-Traffic"],
])

export const RoomSizeSection = () => {
    const { roomSize } = useUrlStateContext()
    return (
        <FilterSection
            isClearDisabled={roomSize === undefined}
            title="Room Size"
            onClear={() => {
                if (roomSize === undefined) {
                    return
                }
                UrlState.current.setPartialState({ roomSize: undefined })
            }}
            clearButtonText="Clear"
        >
            <RoomSizeSectionContent />
        </FilterSection>
    )
}

export const RoomSizeSectionContent = ({
    combinedOverlay = false,
}: {
    combinedOverlay?: boolean | undefined
}) => {
    const selectedRoomSize = useUrlStateContext().roomSize

    return (
        <div
            className={`FilterSection__filterOptions${combinedOverlay ? "Combined" : ""}`}
        >
            {Object.values(RoomSize).map((roomSize) => (
                <button
                    key={roomSize}
                    className={`FilterSection__filterOption ${
                        selectedRoomSize === roomSize ? "active" : ""
                    }`}
                    onClick={() =>
                        UrlState.current.setPartialState({
                            roomSize:
                                roomSize === selectedRoomSize
                                    ? undefined
                                    : roomSize,
                        })
                    }
                >
                    {ROOM_SIZE_MAPPING.get(roomSize)}
                </button>
            ))}
        </div>
    )
}
