import { MemoryRouter, Route, Routes } from "react-router-dom"
import { ToastManager } from "./common/Toast/ToastManager"
import { ConversationList } from "./ConversationList/ConversationList"
import { MessageList } from "./MessageList/MessageList"
import { MessageProfile } from "./MessageProfile/MessageProfile"
import { MessagingProvider } from "./MessagingContext"
import { Settings } from "./Settings/Settings"
import type { Interfaces, Topics } from "./types"

type Props = {
    topics: Topics
    interfaces: Interfaces
    onClose: () => void
}

export function Messaging({ topics, interfaces, onClose }: Props) {
    return (
        <MessagingProvider topics={topics} interfaces={interfaces}>
            <MemoryRouter>
                <Routes>
                    <Route
                        path="/messages/:username"
                        element={<MessageList />}
                    />
                    <Route path="/settings" element={<Settings />} />
                    <Route
                        path="/settings/message-profile"
                        element={<MessageProfile />}
                    />
                    <Route
                        path="*"
                        element={<ConversationList onClose={onClose} />}
                    />
                </Routes>
            </MemoryRouter>
            <ToastManager />
        </MessagingProvider>
    )
}
