import { Region } from "@multimediallc/cb-roomlist-prefetch"
import type {
    PrivatePrices,
    SpyShowPrices,
} from "@multimediallc/cb-roomlist-prefetch"

export enum ActiveFiltersSection {
    None = "",
    Regions = "regions",
    Tags = "tags",
    RoomSize = "roomSize",
    PrivatePrices = "privatePrices",
    SpyPrices = "spyPrices",
    Ages = "ages",
    Languages = "languages",
    Combined = "combined",
}

export const RegionToTitle = new Map<Region, string>([
    [Region.NorthAmerica, "North America"],
    [Region.SouthAmerica, "South America"],
    [Region.Asia, "Asia"],
    [Region.EuropeRussia, "Euro/Russia"],
    [Region.Other, "Others"],
])

export interface AgeRange {
    ageMin: number
    ageMax: number
}

export type PrivatePricesOptions = PrivatePrices[] | undefined

export type SpyPricesOptions = SpyShowPrices[] | undefined
