import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgRotateDevice = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 102 87"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect
            width={42}
            height={80}
            x={2}
            y={5}
            stroke="#fff"
            strokeWidth={4}
            rx={2}
        />
        <path
            stroke="#B3B3B3"
            strokeLinecap="round"
            strokeWidth={4}
            d="M51 38h45a4 4 0 0 1 4 4v39a4 4 0 0 1-4 4H51"
        />
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth={3}
            d="M62.1 5.254c16.4 0 23.455 8.577 26.4 20.746m0 0 2.543-3.021M88.5 26l-4.271-1.84"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgRotateDevice)
const Memo = memo(ForwardRef)
export default Memo
