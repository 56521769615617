import { useEffect, useState } from "react"
import {
    addOrientationChangeListener,
    isOrientationChangeSupported,
    isPortrait,
    removeOrientationChangeListener,
} from "@multimediallc/web-utils"

// TODO move to a more general hooks utils file
export function useIsPortrait(): boolean {
    const [portrait, setPortrait] = useState(
        isPortrait() || !isOrientationChangeSupported(),
    )

    useEffect(() => {
        const onOrientationChange = () => setPortrait(isPortrait())
        addOrientationChangeListener(onOrientationChange)
        return () => {
            removeOrientationChangeListener(onOrientationChange)
        }
    })

    return portrait
}
