import type { ChangeEvent } from "react"
import type React from "react"
import { useEffect, useRef, useState } from "react"
import { t } from "@lingui/macro"
import "./AgeSection.scss"
import "../filterSection.scss"
import {
    DEFAULT_MAX_AGE,
    DEFAULT_MIN_AGE,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../../hooks/urlStateContext"
import { FilterSection } from "../FilterSection"
import type { AgeRange } from "../../types"

export const AgeSection = () => {
    const urlStateContext = useUrlStateContext()

    const isDefaultSelection = () => {
        return (
            (urlStateContext.ageMin === undefined ||
                urlStateContext.ageMin === DEFAULT_MIN_AGE) &&
            (urlStateContext.ageMax === undefined ||
                urlStateContext.ageMax === DEFAULT_MAX_AGE)
        )
    }

    return (
        <FilterSection
            isClearDisabled={isDefaultSelection()}
            title="Ages"
            onClear={() => {
                if (isDefaultSelection()) {
                    return
                }
                UrlState.current.setPartialState({
                    ageMin: DEFAULT_MIN_AGE,
                    ageMax: DEFAULT_MAX_AGE,
                })
            }}
            clearButtonText="reset"
        >
            <AgeSectionContent />
        </FilterSection>
    )
}

export const AgeSectionContent = ({
    combinedOverlay = false,
}: {
    combinedOverlay?: boolean | undefined
}) => {
    const urlStateContext = useUrlStateContext()

    const ageMinInputRef = useRef<HTMLInputElement>(null)
    const ageMaxInputRef = useRef<HTMLInputElement>(null)

    const [rawAgeRange, setRawAgeRange] = useState<AgeRange>({
        ageMin: urlStateContext.ageMin ?? DEFAULT_MIN_AGE,
        ageMax: (urlStateContext.ageMax ?? DEFAULT_MAX_AGE) - 1,
    })

    useEffect(() => {
        setRawAgeRange({
            ageMin: urlStateContext.ageMin ?? DEFAULT_MIN_AGE,
            ageMax: (urlStateContext.ageMax ?? DEFAULT_MAX_AGE) - 1,
        })
    }, [urlStateContext.ageMin, urlStateContext.ageMax])

    const minIsInvalid = (newMin: number): boolean => {
        return (
            isNaN(newMin) ||
            newMin < DEFAULT_MIN_AGE ||
            newMin >= DEFAULT_MAX_AGE
        )
    }

    const handleAgeMinInputBlur = (newMin: number) => {
        let newInputMax = rawAgeRange.ageMax

        // Invalid min value should get reset to the default.
        if (minIsInvalid(newMin)) {
            newMin = DEFAULT_MIN_AGE
        }

        // If min > max, we assume the user intended the min value they just input, and reset max to default.
        if (newMin > newInputMax) {
            newInputMax = DEFAULT_MAX_AGE - 1
        }

        setRawAgeRange({ ageMin: newMin, ageMax: newInputMax })
        UrlState.current.setPartialState({
            ageMin: newMin,
            ageMax: newInputMax + 1,
        })
    }

    const onAgeMinInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ((e.key ?? "") === "Enter") {
            if (ageMinInputRef.current !== null) {
                ageMinInputRef.current.blur()
            }
        }
        preventNonNumericChars(e)
    }

    const getInputMaxAsNumber = (): number => {
        // The max value requires more care than the min value because "" is a valid input.
        // This method should be used to access the numerical value of the input element for max age.
        const inputValue =
            ageMaxInputRef.current?.valueAsNumber ?? DEFAULT_MAX_AGE - 1
        const parsedMax = inputValue ?? DEFAULT_MAX_AGE - 1
        if (isNaN(parsedMax)) {
            return DEFAULT_MAX_AGE - 1
        } else {
            return parsedMax
        }
    }

    const maxIsInvalid = (newMax: number): boolean => {
        return newMax < DEFAULT_MIN_AGE || newMax >= DEFAULT_MAX_AGE
    }

    const handleAgeMaxInputBlur = () => {
        let newMin, newInputMax
        newInputMax = getInputMaxAsNumber()
        newMin = rawAgeRange.ageMin

        // Reset newInputMax if it is invalid.
        if (maxIsInvalid(newInputMax)) {
            newInputMax = DEFAULT_MAX_AGE - 1
        }
        // If max < min assume the user intended the max value they just inputted, and reset min to default.
        if (newInputMax < newMin) {
            newMin = DEFAULT_MIN_AGE
        }

        setRawAgeRange({ ageMin: newMin, ageMax: newInputMax })
        UrlState.current.setPartialState({
            ageMin: newMin,
            ageMax: newInputMax + 1,
        })
    }

    const onAgeMaxInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ((e.key ?? "") === "Enter") {
            if (ageMaxInputRef.current !== null) {
                ageMaxInputRef.current.blur()
            }
        }
        preventNonNumericChars(e)
    }

    const preventNonNumericChars = (
        evt: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        const keyVal = evt.key ?? ""
        // Length check avoids intercepting special keys such as arrows/tab/enter etc, which have multi-character
        // representations, leaving only printable single characters. Then the isNaN check filters anything other
        // than digits (or space, which is already prevented from being entered in <input type="number">)
        if (keyVal.length === 1 && isNaN(Number(keyVal))) {
            evt.preventDefault()
        }
    }

    const getInputMaxAsString = (): string => {
        const rawAgeMax = rawAgeRange.ageMax
        const maxInputStr = isNaN(rawAgeMax) ? "" : rawAgeMax.toString()
        if (
            ageMaxInputRef.current !== null &&
            ageMaxInputRef.current === document.activeElement
        ) {
            // User is actively making changes to input, return input as typed (given that it is numeric).
            return maxInputStr
        } else {
            // User is not actively updating input, correct value to empty string when value is DEFAULT_MAX_AGE.
            return rawAgeMax === DEFAULT_MAX_AGE - 1 ? "" : maxInputStr
        }
    }
    return (
        <div
            className={`AgeSection__rangedInputContainer${combinedOverlay ? "Combined" : ""}`}
        >
            <input
                data-testid="age-mobile-filters-section-min-age-input"
                onBlur={(e) => {
                    handleAgeMinInputBlur(e.target.valueAsNumber)
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setRawAgeRange((prevState) => {
                        return {
                            ...prevState,
                            ageMin: e.target.valueAsNumber,
                        }
                    })
                }}
                onKeyDown={onAgeMinInput}
                ref={ageMinInputRef}
                type="number"
                value={rawAgeRange.ageMin || ""}
            />
            <div className="AgeSection__to">{t`to`}</div>
            <input
                data-testid="age-mobile-filters-section-max-age-input"
                onBlur={() => {
                    handleAgeMaxInputBlur()
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setRawAgeRange((prevState) => {
                        return {
                            ...prevState,
                            ageMax: e.target.valueAsNumber,
                        }
                    })
                }}
                onKeyDown={onAgeMaxInput}
                ref={ageMaxInputRef}
                type="number"
                value={getInputMaxAsString()}
            />
        </div>
    )
}
