import { Trans } from "@lingui/macro"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Button, Overlay, Typography } from "../../common"

import "./DevicePermissionsPrompt.scss"

type DevicePermissionsPromptProps = {
    onPermsAck: () => void
}

export function DevicePermissionsPrompt({
    onPermsAck,
}: DevicePermissionsPromptProps) {
    return (
        <Overlay
            className="DevicePermissionsPrompt"
            data-testid="device-permissions-prompt"
            onClick={() => {}}
        >
            <div className="DevicePermissionsPrompt__content">
                <div className="DevicePermissionsPrompt__promptText">
                    <Typography size={Sizes.mdpx}>
                        <Trans>
                            We need permission to use your camera and microphone
                            in order to proceed. When prompted by the browser,
                            please click &quot;Allow.&quot;
                        </Trans>
                    </Typography>
                    <Typography size={Sizes.mdpx}>
                        <Trans>
                            Visit our{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://support.chaturbate.com/hc/en-us/articles/360040862791/"
                            >
                                support page
                            </a>{" "}
                            for help enabling your devices.
                        </Trans>
                    </Typography>
                </div>
                <Button
                    onClick={onPermsAck}
                    className="DevicePermissionsPrompt__permsAckButton"
                    text={
                        <Typography weight={Weights.bold} size={Sizes.mdpx}>
                            <Trans>Request Device Permissions</Trans>
                        </Typography>
                    }
                />
            </div>
        </Overlay>
    )
}
