import { useCallback, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
    Fanclub,
    MutedMessage,
    Tokens,
} from "../../../components/common/atoms/Icons/Chat"
import { useAppDispatch } from "../../../store/hooks"
import {
    setConversationListScroll,
    useMarkAsReadMutation,
} from "../../../store/messagingSlice"
import { ConfirmationModal } from "../common/ConfirmationModal/ConfirmationModal"
import { ProfilePicture } from "../common/ProfilePicture/ProfilePicture"
import { useMessagingContext } from "../MessagingContext"
import { classNames } from "../utils"
import {
    ACTION_BUTTON_WIDTH,
    MessageActions,
    TOTAL_ACTIONS_WIDTH,
} from "./MessageActions/MessageActions"
import { formatTokens, getFormattedTime, getMessagePreview } from "./utils"
import type { Thread } from "../types"
import "./MessageThread.scss"

const MINIMUM_MOVEMENT_THRESHOLD = 5
const HORIZONTAL_MOVEMENT_RATIO = 1.2
const TEST_IS_TYPING = false

interface MessageThreadProps {
    thread: Thread
    conversationListRef: React.RefObject<HTMLDivElement>
}

export function MessageThread({
    thread,
    conversationListRef,
}: MessageThreadProps) {
    const { dmUnreadData } = useMessagingContext()
    const [markAsRead] = useMarkAsReadMutation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [translateX, setTranslateX] = useState(0)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const touchStartXRef = useRef(0)
    const touchStartYRef = useRef(0)
    const currentTranslateXRef = useRef(0)
    const isScrollingRef = useRef(false)
    const hasStartedSwipingRef = useRef(false)

    const handleTouchStart = useCallback(
        (e: React.TouchEvent) => {
            touchStartXRef.current = e.touches[0].clientX
            touchStartYRef.current = e.touches[0].clientY
            currentTranslateXRef.current = translateX
            isScrollingRef.current = false
            hasStartedSwipingRef.current = false
        },
        [translateX],
    )

    const handleTouchMove = useCallback((e: React.TouchEvent) => {
        const touchX = e.touches[0].clientX
        const touchY = e.touches[0].clientY
        const deltaX = touchStartXRef.current - touchX
        const deltaY = touchStartYRef.current - touchY

        // Determine if the gesture is a swipe or scroll
        if (!isScrollingRef.current && !hasStartedSwipingRef.current) {
            const isHorizontalMovement =
                Math.abs(deltaX) > Math.abs(deltaY) * HORIZONTAL_MOVEMENT_RATIO

            if (
                Math.abs(deltaX) > MINIMUM_MOVEMENT_THRESHOLD ||
                Math.abs(deltaY) > MINIMUM_MOVEMENT_THRESHOLD
            ) {
                if (isHorizontalMovement) {
                    hasStartedSwipingRef.current = true
                } else {
                    isScrollingRef.current = true
                }
            }
        }

        // Only handle swipe if we've determined it's a horizontal swipe
        if (hasStartedSwipingRef.current) {
            const newTranslateX = Math.max(
                Math.min(currentTranslateXRef.current - deltaX, 0),
                -TOTAL_ACTIONS_WIDTH,
            )
            setTranslateX(newTranslateX)
        }
    }, [])

    const handleTouchEnd = useCallback(() => {
        const finalPosition =
            translateX < -ACTION_BUTTON_WIDTH ? -TOTAL_ACTIONS_WIDTH : 0
        setTranslateX(finalPosition)
        currentTranslateXRef.current = finalPosition
    }, [translateX])

    const handleMarkAsRead = () => {
        const numUnread = thread.num_unread ?? 0
        const username = thread.other_user?.username
        const hasUnread = numUnread > 0

        if (hasUnread && username) {
            markAsRead(username)
            dmUnreadData.removeUnreadRecipient(username)
        }
    }

    const handleThreadClick = useCallback(() => {
        // If swiped open, close it instead of navigating
        if (translateX < 0) {
            setTranslateX(0)
            currentTranslateXRef.current = 0
            return
        }

        if (conversationListRef.current) {
            dispatch(
                setConversationListScroll(
                    conversationListRef.current.scrollTop,
                ),
            )
        }

        handleMarkAsRead()

        navigate(`/messages/${thread.other_user.username}`)
    }, [
        translateX,
        thread,
        conversationListRef,
        dispatch,
        markAsRead,
        navigate,
        handleMarkAsRead,
    ])

    const handleUnmute = useCallback(() => {
        // TODO: Implement unmute logic here
        setTranslateX(0)
        currentTranslateXRef.current = 0
    }, [thread.other_user.username])

    const handleDelete = useCallback(() => {
        setShowDeleteModal(true)
    }, [])

    const handleConfirmDelete = useCallback(() => {
        // TODO: Implement delete logic here
        setShowDeleteModal(false)
        setTranslateX(0)
        currentTranslateXRef.current = 0
    }, [thread.other_user.username])

    return (
        <>
            <div
                className="message-thread"
                data-testid="message-thread"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div
                    className="message-item"
                    data-testid="message-item"
                    onClick={handleThreadClick}
                    style={{
                        transform: `translateX(${translateX}px)`,
                    }}
                >
                    <ProfilePicture
                        username={thread.other_user.username}
                        avatarUrl={thread.other_user?.avatarUrl}
                        isOnline={thread.other_user?.isOnline}
                        isLive={thread.other_user?.isLive}
                    />
                    <div
                        className="message-content"
                        data-testid="message-content"
                    >
                        <div className="username-row">
                            <span className="username" data-testid="username">
                                <span className="username-text">
                                    {thread.other_user?.username}
                                </span>
                                {thread.other_user?.inFanclub && (
                                    <span
                                        className="fanclub-icon"
                                        data-testid="fanclub-icon"
                                    >
                                        <Fanclub width={16} height={16} />
                                    </span>
                                )}
                                {(thread.other_user?.tokensSpent ?? 0) > 0 && (
                                    <span
                                        className="tokens-badge"
                                        data-testid="tokens-badge"
                                    >
                                        <span className="tokens-icon">
                                            <Tokens width={10} height={10} />
                                        </span>
                                        <span
                                            className="tokens-amount"
                                            data-testid="tokens-amount"
                                        >
                                            {formatTokens(
                                                thread.other_user.tokensSpent!,
                                            )}
                                        </span>
                                    </span>
                                )}
                            </span>
                            <span className="timestamp" data-testid="timestamp">
                                {getFormattedTime(thread.created_at)}
                            </span>
                        </div>
                        <div
                            className="preview-container"
                            data-testid="preview-container"
                        >
                            <div
                                className={classNames("preview", {
                                    unread: thread.num_unread > 0,
                                })}
                                data-testid="message-preview"
                            >
                                {getMessagePreview(thread, TEST_IS_TYPING)}
                            </div>
                            {thread.other_user?.isMuted && (
                                <div
                                    className="muted-icon"
                                    data-testid="muted-icon"
                                    role="status"
                                >
                                    <MutedMessage width={16} height={16} />
                                </div>
                            )}
                            {thread.num_unread > 0 && (
                                <div
                                    className="unread-indicator"
                                    data-testid="unread-indicator"
                                    role="status"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <MessageActions
                    onUnmute={handleUnmute}
                    onDelete={handleDelete}
                    isMuted={thread.other_user?.isMuted ?? false}
                    translateX={translateX}
                />
            </div>
            <ConfirmationModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
                title="Are you sure you want to delete this chat?"
            />
        </>
    )
}
