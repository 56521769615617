import { useEffect, useState } from "react"
import { CheckmarkCircleGreen } from "../../../common/atoms/Icons/Chat"
import { classNames } from "../../utils"
import styles from "./Toast.module.scss"

interface ToastProps {
    message: string
    position: "top" | "bottom"
    onClose: () => void
}

export const Toast: React.FC<ToastProps> = ({ message, position, onClose }) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(true)
        const timer = setTimeout(() => {
            setVisible(false)
            setTimeout(onClose, 2000)
        }, 2500)

        return () => clearTimeout(timer)
    }, [onClose])

    return (
        <div
            className={classNames(styles.toast, styles[position], {
                [styles.visible]: visible,
            })}
            role="alert"
        >
            <CheckmarkCircleGreen className={styles.iconCheck} />
            {message}
        </div>
    )
}
