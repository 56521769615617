import {
    BellGrey,
    BellStrikethrough,
    TrashWhite,
} from "../../../../components/common/atoms/Icons/Chat"
import "./MessageActions.scss"

export const ACTION_BUTTON_WIDTH = 64
export const TOTAL_ACTIONS_WIDTH = ACTION_BUTTON_WIDTH * 2
const ICON_SIZE = 24

interface MessageActionsProps {
    onUnmute: () => void
    onDelete: () => void
    isMuted: boolean
    translateX: number
}

export function MessageActions({
    onUnmute,
    onDelete,
    isMuted,
    translateX,
}: MessageActionsProps) {
    // Calculate visibility based on swipe progress
    const visibility = Math.min(Math.abs(translateX) / TOTAL_ACTIONS_WIDTH, 1)

    return (
        <div
            className="message-actions"
            data-testid="message-actions"
            style={{
                transform: `translateX(${TOTAL_ACTIONS_WIDTH - TOTAL_ACTIONS_WIDTH * visibility}px)`,
                visibility: visibility > 0 ? "visible" : "hidden",
            }}
        >
            <button
                className="action-button unmute"
                onClick={onUnmute}
                aria-label={isMuted ? "Unmute" : "Mute"}
                data-testid="mute-button"
            >
                <div className="action-content">
                    <div className="action-icon" data-testid="bell-icon">
                        {isMuted ? (
                            <BellStrikethrough
                                width={ICON_SIZE}
                                height={ICON_SIZE}
                            />
                        ) : (
                            <BellGrey width={ICON_SIZE} height={ICON_SIZE} />
                        )}
                    </div>
                    <span className="action-text" data-testid="mute-text">
                        {isMuted ? "Unmute" : "Mute"}
                    </span>
                </div>
            </button>
            <button
                className="action-button delete"
                onClick={onDelete}
                aria-label="Delete"
                data-testid="delete-button"
            >
                <div className="action-content">
                    <div className="action-icon" data-testid="trash-icon">
                        <TrashWhite width={ICON_SIZE} height={ICON_SIZE} />
                    </div>
                    <span className="action-text" data-testid="delete-text">
                        Delete
                    </span>
                </div>
            </button>
        </div>
    )
}
