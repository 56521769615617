import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgClose = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path d="M7.15 6.089a.125.125 0 0 1 0-.177l4.632-4.631A.75.75 0 0 0 10.72.22L6.089 4.848a.125.125 0 0 1-.177 0L1.28.22A.75.75 0 1 0 .22 1.28l4.63 4.632a.125.125 0 0 1 0 .177L.22 10.72a.75.75 0 1 0 1.06 1.06l4.632-4.63a.125.125 0 0 1 .177 0l4.63 4.63a.75.75 0 0 0 1.062-1.06z" />
    </svg>
)
const ForwardRef = forwardRef(SvgClose)
const Memo = memo(ForwardRef)
export default Memo
