import {
    PrivatePrices,
    Region,
    RoomSize,
    SpyShowPrices,
} from "@multimediallc/cb-roomlist-prefetch"
import { ActiveFiltersSection } from "./types"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

// Default ordering for sections in both the filter tabs and the combined filters overlay.
// Tabs with active filters are moved to the front of the tab list but otherwise maintain the same order.
export const SECTION_ORDER = [
    ActiveFiltersSection.Tags,
    ActiveFiltersSection.Regions,
    ActiveFiltersSection.RoomSize,
    ActiveFiltersSection.PrivatePrices,
    ActiveFiltersSection.SpyPrices,
    ActiveFiltersSection.Ages,
    ActiveFiltersSection.Languages,
]

export const FilterSectionToStateKeys = new Map<
    ActiveFiltersSection,
    (keyof IURLState)[]
>([
    [ActiveFiltersSection.Regions, ["regions"]],
    [ActiveFiltersSection.Tags, ["tags"]],
    [ActiveFiltersSection.RoomSize, ["roomSize"]],
    [ActiveFiltersSection.PrivatePrices, ["privatePrices"]],
    [ActiveFiltersSection.SpyPrices, ["spyShowPrices"]],
    [ActiveFiltersSection.Ages, ["ageMax", "ageMin"]],
    [ActiveFiltersSection.Languages, ["spokenLanguages"]],
])

export const FilterSectionTitles = new Map<ActiveFiltersSection, string>([
    [ActiveFiltersSection.Regions, "Regions"],
    [ActiveFiltersSection.Tags, "Tags"],
    [ActiveFiltersSection.RoomSize, "Room Size"],
    [ActiveFiltersSection.PrivatePrices, "Private Show (tokens/min)"],
    [ActiveFiltersSection.SpyPrices, "Spy Show (tokens/min)"],
    [ActiveFiltersSection.Ages, "Ages"],
    [ActiveFiltersSection.Languages, "Languages"],
])

export const RegionToLabel = new Map<Region, string>([
    [Region.NorthAmerica, "North America"],
    [Region.SouthAmerica, "South America"],
    [Region.Asia, "Asia"],
    [Region.EuropeRussia, "Europe/Russia"],
    [Region.Other, "Other"],
])

export const PrivatePriceToLabel = new Map<PrivatePrices, string>([
    [PrivatePrices.Value6, "6 tks"],
    [PrivatePrices.Range12to18, "12 - 18 tks"],
    [PrivatePrices.Range30to42, "30 - 42 tks"],
    [PrivatePrices.Range60to72, "60 - 72 tks"],
    [PrivatePrices.Range90Plus, "90+ tks"],
])

export const SpyPriceToLabel = new Map<SpyShowPrices, string>([
    [SpyShowPrices.Value6, "6 tks"],
    [SpyShowPrices.Range12to18, "12 - 18 tks"],
    [SpyShowPrices.Range30to42, "30 - 42 tks"],
    [SpyShowPrices.Range54to66, "54 - 66 tks"],
    [SpyShowPrices.Range90Plus, "90+ tks"],
])

export const RoomSizeToLabel = new Map<RoomSize, string>([
    [RoomSize.SMALL, "Intimate"],
    [RoomSize.MEDIUM, "Mid-Sized"],
    [RoomSize.LARGE, "High-Traffic"],
])
