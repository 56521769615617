import { LiveRing } from "../../../common/atoms/Icons/Chat"
import "./ProfilePicture.scss"

interface ProfilePictureProps {
    username: string
    avatarUrl?: string
    size?: number // size of the avatar
    isOnline?: boolean
    isLive?: boolean
}

export function ProfilePicture({
    username,
    avatarUrl,
    size = 40,
    isOnline = false,
    isLive = false,
}: ProfilePictureProps) {
    const containerSize = size + 8 // container is 8px larger than avatar

    return (
        <div
            className="profile-picture"
            style={{ width: containerSize, height: containerSize }}
            data-testid="profile-picture"
        >
            {isLive && (
                <div className="live-ring" data-testid="live-ring">
                    <LiveRing width={containerSize} height={containerSize} />
                </div>
            )}
            {avatarUrl ? (
                <img
                    src={avatarUrl}
                    alt={username}
                    className="avatar"
                    style={{ width: size, height: size }}
                />
            ) : (
                <div
                    className="letter-avatar"
                    style={{ width: size, height: size }}
                >
                    {username?.charAt(0)?.toUpperCase()}
                </div>
            )}
            {isOnline && !isLive && (
                <div className="online-indicator" role="status" />
            )}
            {isLive && <div className="live-badge">LIVE</div>}
        </div>
    )
}
