import type React from "react"
import styles from "./MessageProfileFormActions.module.scss"

export const MessageProfileFormActions: React.FC = () => {
    return (
        <div className={styles.container}>
            <button className={styles.cancel}>Cancel</button>
            <button className={styles.save}>Save</button>
        </div>
    )
}
