import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCloseWhite = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 20"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <g clipPath="url(#closeWhite_svg__a)" filter="url(#closeWhite_svg__b)">
            <path
                fill="#fff"
                d="m11.372 8.5 6.356-6.356A.93.93 0 0 0 18 1.487a.92.92 0 0 0-.272-.657l-.557-.557A.92.92 0 0 0 16.514 0a.92.92 0 0 0-.658.272L9.5 6.629 3.144.273A.92.92 0 0 0 2.486 0a.92.92 0 0 0-.657.272L1.272.83a.93.93 0 0 0 0 1.314l6.356 6.357-6.356 6.356a.92.92 0 0 0-.271.657c0 .25.096.483.271.658l.557.557a.92.92 0 0 0 .657.271.92.92 0 0 0 .658-.271L9.5 10.373l6.356 6.356a.92.92 0 0 0 .658.271.92.92 0 0 0 .657-.271l.557-.557a.92.92 0 0 0 .272-.658.92.92 0 0 0-.272-.657z"
            />
        </g>
        <defs>
            <clipPath id="closeWhite_svg__a">
                <path fill="#fff" d="M0 0h19v20H0z" />
            </clipPath>
            <filter
                id="closeWhite_svg__b"
                width={21}
                height={20.999}
                x={-1}
                y={-0.999}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={1} />
                <feGaussianBlur stdDeviation={1} />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgCloseWhite)
const Memo = memo(ForwardRef)
export default Memo
