import { useParams } from "react-router-dom"
import "./Message.scss"
import { useLongPress } from "../../../hooks/useLongPress"
import { MessageMenu } from "../MessageMenu/MessageMenu"
import type { Message } from "../types"

type Props = {
    message: Message
    showAvatar: boolean
    showMenuMessageId: string | null
    setShowMenuMessageId: (messageId: string | null) => void
}

export function Message({
    message,
    showAvatar,
    showMenuMessageId,
    setShowMenuMessageId,
}: Props) {
    const { username } = useParams<{ username: string }>()
    const isReceived = message?.from_user?.username === username
    const { ref } = useLongPress({
        onLongPress: () => {
            setShowMenuMessageId(message.i)
        },
    })

    const onCloseMenu = () => {
        setShowMenuMessageId(null)
    }
    const showMenu = showMenuMessageId === message.i

    return (
        <div
            className={`message ${isReceived ? "received" : "sent"}`}
            ref={ref}
            data-testid="message"
        >
            {showMenu && (
                <MessageMenu
                    message={message}
                    onClose={onCloseMenu}
                    triggerRef={ref}
                />
            )}
            {showAvatar && <div className="avatar" />}
            <span>{message.m}</span>
        </div>
    )
}
