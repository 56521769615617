import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { useNavigate, useParams } from "react-router-dom"
import AutoSizer from "react-virtualized-auto-sizer"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    DM_THREAD_PAGE_LIMIT,
    messagingApi,
    useGetHistoryQuery,
    useGetThreadsQuery,
    useMarkAsReadMutation,
} from "../../../store/messagingSlice"
import { Spinner } from "../../common"
import { IconButton } from "../common/IconButton/IconButton"
import { MessageHeaderMenu } from "../MessageHeaderMenu/MessageHeaderMenu"
import { MessageInputBar } from "../MessageInputBar/MessageInputBar"
import { MessageMedia } from "../MessageMedia/MessageMedia"
import { MessageRow } from "../MessageRow/MessageRow"
import { useMessagingContext } from "../MessagingContext"
import { getMobileSvgPath } from "../utils"
import { VirtualList } from "../VirtualList/VirtualList"
import type { MessageRowData } from "../MessageRow/MessageRow"
import type { Message } from "../types"
import type { ListChildComponentProps, VariableSizeList } from "react-window"
import "./MessagesList.scss"

const TEST_MEDIA_MESSAGE = false

const MESSAGES_BATCH_SIZE = 50
const OVERSCAN_COUNT = 5
const SCROLL_THRESHOLD = 84
const SCROLL_THROTTLE_DELAY = 500
const LOADER_HEIGHT = 32
const NEAR_BOTTOM_THRESHOLD = 70

const Row = (props: ListChildComponentProps<MessageRowData>) => (
    <MessageRow {...props} />
)

/** Prevents scroll jank by pre-measuring dynamic message heights for react-window */
const HeightPreCalculator = ({
    messages,
    messageHeights,
    itemData,
}: {
    messages: Message[]
    messageHeights: React.MutableRefObject<Record<string, number>>
    itemData: MessageRowData
}) => {
    const unmeasured = messages.filter((msg) => !messageHeights.current[msg.i])

    if (unmeasured.length === 0) return null

    return (
        <div
            style={{
                position: "absolute",
                opacity: 0,
                visibility: "hidden",
                pointerEvents: "none",
            }}
        >
            {unmeasured.map((message) => (
                <MessageRow
                    key={`shadow-${message.i}`}
                    index={messages.indexOf(message) + 1}
                    style={{ position: "relative" }}
                    data={{
                        ...itemData,
                    }}
                />
            ))}
        </div>
    )
}

export function MessageList() {
    const navigate = useNavigate()
    const { username = "" } = useParams<{ username: string }>()
    const { dmUnreadData } = useMessagingContext()
    const [offset, setOffset] = useState<string>()
    const { data, isLoading, isFetching, error } = useGetHistoryQuery({
        username: username || "",
        offset,
    })
    const [markAsRead] = useMarkAsReadMutation()
    const dispatch = useAppDispatch()
    const messages = useMemo(() => data?.messages || [], [data?.messages])

    const [showMenuMessageId, setShowMenuMessageId] = useState<string | null>(
        null,
    )
    const [isAllHistoryLoaded, setIsAllHistoryLoaded] = useState(false)
    const [isInitialScrollComplete, setIsInitialScrollComplete] =
        useState(false)
    const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false)

    const listRef = useRef<VariableSizeList>(null)
    const listOuterRef = useRef<HTMLDivElement>(null)
    const messageHeights = useRef<Record<string, number>>({})
    const initialLoadDone = useRef(false)
    const oldMessagesLengthRef = useRef<number>(0)
    const isAdjustingScroll = useRef(false)
    const targetScrollIndex = useRef<number | null>(null)
    const scrollOffsetBeforeLoad = useRef<number>(0)
    const loadTimeoutRef = useRef<NodeJS.Timeout>()
    const restoreTimeoutRef = useRef<NodeJS.Timeout>()
    const myUsername = useAppSelector(
        (state) => state.user.loggedInUser?.username,
    )
    const isNearBottom = useRef(true)

    const { data: threadsData } = useGetThreadsQuery({
        offset: 0,
        limit: DM_THREAD_PAGE_LIMIT,
    })
    const hasUnreadMessages = useMemo(() => {
        return threadsData?.threads.some(
            (thread) =>
                thread.other_user.username === username &&
                thread.num_unread > 0,
        )
    }, [threadsData, username])

    const getItemSize = useCallback(
        (index: number) => {
            if (index === 0) return isAllHistoryLoaded ? 0 : LOADER_HEIGHT
            const messageId = messages[index - 1]?.i
            return messageHeights.current[messageId] || 0
        },
        [messages, isAllHistoryLoaded],
    )

    const restoreScrollPosition = useCallback(
        (index: number) => {
            if (!listRef.current || !listOuterRef.current) return

            clearTimeout(restoreTimeoutRef.current)
            isAdjustingScroll.current = true
            const newContentHeight = Array.from({ length: index }, (_, i) =>
                getItemSize(i),
            ).reduce((sum, height) => sum + height, 0)

            const finalPosition =
                scrollOffsetBeforeLoad.current < SCROLL_THRESHOLD
                    ? newContentHeight
                    : newContentHeight + scrollOffsetBeforeLoad.current

            listRef.current.scrollTo(finalPosition - LOADER_HEIGHT)

            restoreTimeoutRef.current = setTimeout(() => {
                isAdjustingScroll.current = false
                targetScrollIndex.current = null
                restoreTimeoutRef.current = undefined
            }, 0)
        },
        [getItemSize],
    )

    const scrollToBottom = useCallback(() => {
        if (!listRef.current || !messages.length) return
        listRef.current.scrollToItem(messages.length, "end")
        setTimeout(() => {
            requestAnimationFrame(() => {
                listRef?.current?.scrollToItem(messages.length, "end")
            })
        }, 0)
        setIsInitialScrollComplete(true)
    }, [messages])

    const handleHeightChange = useCallback(
        (index: number, height: number, messageId: string) => {
            if (height === 0 || messageHeights.current[messageId] === height)
                return

            messageHeights.current[messageId] = height

            if (listRef.current) {
                listRef.current.resetAfterIndex(index)

                if (
                    isAdjustingScroll.current &&
                    targetScrollIndex.current !== null
                ) {
                    restoreScrollPosition(targetScrollIndex.current)
                }
            }
        },
        [restoreScrollPosition],
    )

    const handleScroll = useCallback(
        ({ scrollOffset }: { scrollOffset: number }) => {
            if (!isAdjustingScroll.current) {
                scrollOffsetBeforeLoad.current = scrollOffset
            }

            if (listOuterRef.current) {
                const listOuter = listOuterRef.current
                const scrolledPosition =
                    listOuter.scrollHeight - listOuter.scrollTop
                const nearBottom =
                    scrolledPosition <=
                    listOuter.clientHeight + NEAR_BOTTOM_THRESHOLD

                isNearBottom.current = nearBottom

                if (nearBottom && hasUnreadMessages) {
                    markAsRead(username)
                    dmUnreadData.removeUnreadRecipient(username)
                }
            }

            if (
                !initialLoadDone.current ||
                !messages.length ||
                isFetching ||
                isAllHistoryLoaded ||
                isAdjustingScroll.current ||
                loadTimeoutRef.current
            )
                return

            if (scrollOffset < SCROLL_THRESHOLD) {
                clearTimeout(loadTimeoutRef.current)
                loadTimeoutRef.current = setTimeout(() => {
                    setIsLoadingOlderMessages(true)
                    setOffset(messages[0].i)
                    loadTimeoutRef.current = undefined
                }, SCROLL_THROTTLE_DELAY)
            }
        },
        [
            isAllHistoryLoaded,
            messages,
            isFetching,
            isLoadingOlderMessages,
            markAsRead,
            username,
            hasUnreadMessages,
        ],
    )

    useLayoutEffect(() => {
        if (!messages.length || !listRef.current) return

        if (!initialLoadDone.current) {
            initialLoadDone.current = true
            oldMessagesLengthRef.current = messages.length
            setIsAllHistoryLoaded(messages.length < MESSAGES_BATCH_SIZE)
            scrollToBottom()
        }
    }, [messages, scrollToBottom])

    useLayoutEffect(() => {
        if (!messages.length || !listRef.current || !listOuterRef.current)
            return

        const newLength = messages.length
        const oldLength = oldMessagesLengthRef.current
        const diff = newLength - oldLength

        if (isLoadingOlderMessages && diff > 0 && oldLength > 0) {
            isAdjustingScroll.current = true
            targetScrollIndex.current = diff + 1
            listRef?.current?.resetAfterIndex(0, true)
            restoreScrollPosition(diff + 1)
            setIsAllHistoryLoaded(diff < MESSAGES_BATCH_SIZE)
            setIsLoadingOlderMessages(false)
        }

        const latestMessage = messages[newLength - 1]
        const isFromMe = latestMessage?.from_user.username === myUsername
        const isSingleNewMessage = diff === 1

        const shouldScrollToBottom =
            isSingleNewMessage &&
            !isLoadingOlderMessages &&
            (isNearBottom.current || isFromMe)

        if (shouldScrollToBottom) {
            scrollToBottom()
        }
        oldMessagesLengthRef.current = newLength
    }, [messages, restoreScrollPosition, myUsername, isLoadingOlderMessages])

    useEffect(() => {
        return () => {
            dispatch(
                messagingApi.util.invalidateTags([
                    { type: "History", id: username },
                ]),
            )
        }
    }, [username])

    const itemData = useMemo(
        () => ({
            messages,
            showMenuMessageId,
            setShowMenuMessageId,
            username,
            onHeightChange: handleHeightChange,
            isAllHistoryLoaded,
            isLoadingOlderMessages,
        }),
        [
            messages,
            showMenuMessageId,
            username,
            handleHeightChange,
            isAllHistoryLoaded,
            isLoadingOlderMessages,
        ],
    )

    const showLoading =
        (isLoading && !messages.length) || !isInitialScrollComplete
    const showError = !isLoading && error
    const showEmpty = !isLoading && !messages.length

    return (
        <div className="messages-list">
            <div className="messages-header">
                <IconButton
                    className="back-button"
                    icon={getMobileSvgPath("chevron_left_blue")}
                    iconAlt="Back"
                    onClick={() => navigate(-1)}
                />
                <div className="avatar" />
                <div className="username">{username}</div>
                <MessageHeaderMenu />
            </div>
            <div className="messages-content">
                {showLoading && (
                    <div className="messages-status loading">
                        <Spinner size="md" />
                    </div>
                )}
                {showError ? (
                    <div className="messages-status">
                        Error loading messages
                    </div>
                ) : showEmpty ? (
                    <div className="messages-status">No messages</div>
                ) : (
                    <>
                        <HeightPreCalculator
                            messages={messages}
                            messageHeights={messageHeights}
                            itemData={itemData}
                        />
                        <AutoSizer>
                            {({ height, width }) => (
                                <VirtualList<MessageRowData>
                                    className="virtual-list"
                                    height={height}
                                    width={width}
                                    itemCount={messages.length + 1}
                                    itemSize={getItemSize}
                                    overscanCount={OVERSCAN_COUNT}
                                    onScroll={handleScroll}
                                    itemData={itemData}
                                    ref={listRef}
                                    outerRef={listOuterRef}
                                    useIsScrolling
                                >
                                    {Row}
                                </VirtualList>
                            )}
                        </AutoSizer>
                    </>
                )}
            </div>
            {TEST_MEDIA_MESSAGE && (
                <>
                    <MessageMedia mockSufficientTokens />
                    <MessageMedia mockSufficientTokens={false} />
                </>
            )}
            <MessageInputBar />
        </div>
    )
}
