import { t } from "@lingui/macro"
import { extractKeywords } from "@multimediallc/cb-roomlist-prefetch"

export const getKeywordString = (searchString?: string) => {
    const [searchKeywords, keywordHashtags] = extractKeywords(searchString)
    const formattedHashtags = keywordHashtags.map((keyword) => `#${keyword}`)
    const keywordString = [...searchKeywords, ...formattedHashtags].join(" ")
    return keywordString
}

export const getRoomlistHeaderText = (categorySlug: string): string =>
    ({
        "mature-cams": t`Mature Cams (50+)`,
        "exhibitionist-cams": t`Exhibitionist Cams`,
        "spy-on-cams": t`Private Shows`,
        "hidden-cams": t`Community Controlled Shows`,
        "new-cams": t`New Cams`,
        "gaming-cams": t`Gaming Cams`,
    })[categorySlug] ?? ""
