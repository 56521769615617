import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../hooks/urlStateContext"
import { RemoveFilterIcon } from "../../../../common/atoms/Icons/Others"
import { FilterSectionTitles, FilterSectionToStateKeys } from "../constants"
import { useFiltersContext } from "../FiltersContext"
import { getLabelForFilterSection } from "../helpers"
import { ActiveFiltersSection } from "../types"
import "./MobileFilterSectionTab.scss"

interface SectionTabProps {
    sectionId: ActiveFiltersSection
}

export function MobileFilterSectionTab({ sectionId }: SectionTabProps) {
    const { overlayedSection, setOverlayedSection } = useFiltersContext()
    const urlState = useUrlStateContext()
    const sectionLabel = getLabelForFilterSection(sectionId, urlState)
    const sectionKeys = FilterSectionToStateKeys.get(sectionId) ?? []

    const isSelected = overlayedSection === sectionId
    const handleTabClick = () => {
        setOverlayedSection(isSelected ? ActiveFiltersSection.None : sectionId)
    }

    if (sectionLabel !== undefined) {
        return (
            <div className="MobileFilterSectionTab">
                <div
                    className="MobileFilterSectionTab__Label"
                    data-testid={`filter-nav-label-${sectionId}`}
                    onClick={handleTabClick}
                >
                    {sectionLabel}
                    <div
                        className="MobileFilterSectionTab__Clear"
                        data-testid={`filter-nav-clear-${sectionId}`}
                        onClick={(event) => {
                            UrlState.current.clearStateKeys(sectionKeys)
                            // Prevent triggering overlay when clearing section filters
                            event.stopPropagation()
                        }}
                    >
                        <RemoveFilterIcon />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="MobileFilterSectionTab">
            <button
                className={`MobileFilterSectionTab__Button${sectionId === overlayedSection ? " selected" : ""}`}
                data-testid={`filter-nav-button-${sectionId}`}
                onClick={handleTabClick}
            >
                {FilterSectionTitles.get(sectionId)}
            </button>
        </div>
    )
}
