import type React from "react"
import "./ConfirmationModal.scss"

interface ConfirmationModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    title: string
    confirmText?: string
    cancelText?: string
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    title,
    confirmText = "Delete",
    cancelText = "Cancel",
}) => {
    if (!isOpen) return null

    const handleContentClick = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <div
            data-testid="modal-overlay"
            className="modal-overlay"
            onClick={onClose}
        >
            <div
                data-testid="modal-content"
                className="modal-content"
                onClick={handleContentClick}
            >
                <h2 data-testid="modal-title" className="modal-title">
                    {title}
                </h2>
                <div className="modal-buttons">
                    <button
                        data-testid="modal-cancel-button"
                        className="modal-button modal-button--cancel"
                        onClick={onClose}
                    >
                        {cancelText}
                    </button>
                    <button
                        data-testid="modal-confirm-button"
                        className="modal-button modal-button--delete"
                        onClick={onConfirm}
                    >
                        {confirmText}
                    </button>
                </div>
            </div>
        </div>
    )
}
