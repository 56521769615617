import { classNames } from "../../utils"
import styles from "./UnreadBadge.module.scss"

interface UnreadBadgeProps {
    count: number
}

export function UnreadBadge({ count }: UnreadBadgeProps) {
    if (count <= 0) {
        return null
    }

    const displayCount = count > 99 ? "99+" : count.toString()

    return (
        <div
            className={classNames(styles.unreadBadge, {
                [styles.small]: count <= 9,
                [styles.medium]: count > 9 && count <= 99,
                [styles.large]: count > 99,
            })}
            data-testid="unread-badge"
        >
            {displayCount}
        </div>
    )
}
