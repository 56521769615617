import React from "react"
import { useNavigate } from "react-router-dom"
import { InputToggle } from "../../common/atoms/InputToggle"
import { Page } from "../common/Page/Page"
import { getMobileSvgPath } from "../utils"
import styles from "./Settings.module.scss"

const Setting: React.FC<{ settingName: string }> = ({ settingName }) => {
    const [checked, setChecked] = React.useState(false)
    return (
        <div className={styles.setting}>
            <span>{settingName}</span>
            <InputToggle
                className={styles.toggle}
                onToggle={() => setChecked(!checked)}
                checked={checked}
            />
        </div>
    )
}

export const Settings: React.FC = () => {
    const navigate = useNavigate()
    return (
        <Page title="Settings" className={styles.page}>
            <div
                className={styles.messageProfile}
                onClick={() => navigate("/settings/message-profile")}
            >
                <div className={styles.avatar} />
                <span>Message Profile</span>
                <img src={getMobileSvgPath("chevron_right_light")} />
            </div>
            <div className={styles.settingGroup}>
                <h2>Active status</h2>
                <Setting settingName="Show when you're active" />
                <p>
                    Your contacts can see when you&#39;re active or when you
                    were last online
                </p>
            </div>
            <div className={styles.settingGroup}>
                <h2>New message notification</h2>
                <Setting settingName="On-site toast notification" />
                <Setting settingName="Mobile push notification" />
                <Setting settingName="Email notification" />
            </div>
        </Page>
    )
}
