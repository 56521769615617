import type { ComponentPropsWithRef, PropsWithChildren } from "react"
import { Sizes, Weights } from "../../../../interfaces/sizing"
import { mergeClasses } from "../../../../utils/css"
import { CloseButton } from "../../atoms/CloseButton"
import { CbBrandIcon } from "../../atoms/Icons/Others"
import { Typography } from "../../atoms/Typography"
import "./Notification.scss"

export enum NotificationPosition {
    top = "top",
    bottom = "bottom",
}

interface NotificationProps extends ComponentPropsWithRef<"div"> {
    position: NotificationPosition
    title: string
    onCloseClick: () => void
    show: boolean
}

export function Notification({
    position,
    title,
    onCloseClick,
    show,
    children,
    ...props
}: PropsWithChildren<NotificationProps>) {
    const containerClassNames = mergeClasses(
        props.className,
        "cbr-notification__container",
        position === NotificationPosition.top
            ? "cbr-notification--top"
            : "cbr-notification--bottom",
    )

    if (!show) {
        return undefined
    }
    return (
        <div
            data-testid="notification"
            {...props}
            className={containerClassNames}
        >
            <div className="cbr-notification__content">
                <CbBrandIcon className="cbr-notification__brandIcon" />
                <div>
                    <Typography
                        className="cbr-notification__title"
                        weight={Weights.bold}
                        size={Sizes.xspx}
                    >
                        {title}
                    </Typography>
                    {children !== undefined && (
                        <Typography
                            className="cbr-notification__secondaryText"
                            size={Sizes.xspx}
                        >
                            {children}
                        </Typography>
                    )}
                </div>
            </div>
            <CloseButton
                size="12"
                onClick={onCloseClick}
                className="cbr-notification__closeButton"
            />
        </div>
    )
}
