import { useNavigate } from "react-router-dom"
import { classNames, getMobileSvgPath } from "../../utils"
import { IconButton } from "../IconButton/IconButton"
import styles from "./Page.module.scss"

type Props = {
    title?: string
    className?: string
    onBack?: () => void
    children: React.ReactNode
}

export const Page: React.FC<Props> = ({
    title,
    className,
    onBack,
    children,
}) => {
    const navigate = useNavigate()
    return (
        <div className={classNames(styles.page, className)}>
            {title && (
                <div className={styles.header}>
                    <IconButton
                        icon={getMobileSvgPath("chevron_left_blue")}
                        iconAlt="Back"
                        onClick={onBack || (() => navigate(-1))}
                        className={styles.backButton}
                    />
                    <h1 className={styles.title}>{title}</h1>
                </div>
            )}
            {children}
        </div>
    )
}
