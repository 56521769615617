import {
    differenceInDays,
    differenceInHours,
    format,
    isSameDay,
    isToday,
    isYesterday,
} from "date-fns"
import type { Message } from "../types"

export const getFormattedTime = (
    currentMessage: Message,
    previousMessage: Message | null,
    isAllHistoryLoaded: boolean = false,
): string | null => {
    if (!previousMessage) {
        return isAllHistoryLoaded ? "Start of conversation" : null
    }

    const currentTime = new Date(currentMessage.created_at * 1000)
    const previousTime = new Date(previousMessage.created_at * 1000)

    if (isToday(currentTime) && isYesterday(previousTime)) {
        return `Today ${format(currentTime, "h:mmaaa")}`
    }

    if (!isSameDay(currentTime, previousTime)) {
        if (differenceInDays(currentTime, previousTime) < 7) {
            return format(currentTime, "EEE h:mmaaa")
        } else {
            return format(currentTime, "MMMM d, yyyy")
        }
    }

    if (
        isToday(currentTime) &&
        differenceInHours(currentTime, previousTime) > 1
    ) {
        return format(currentTime, "h:mmaaa")
    }

    return null
}

export const getTimeStamp = (
    currentMessage: Message,
    previousMessage: Message | null,
    isAllHistoryLoaded: boolean,
) => {
    const ts = getFormattedTime(
        currentMessage,
        previousMessage || null,
        isAllHistoryLoaded,
    )
    return ts ? <div className="message-timestamp">{ts}</div> : null
}

export const showAvatar = (
    username: string | undefined,
    message: Message,
    nextMessage: Message | undefined,
): boolean => {
    if (!username || username !== message.from_user.username) {
        return false
    }

    return (
        !nextMessage ||
        nextMessage.from_user.username !== message.from_user.username
    )
}
