import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgFanclub = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <circle cx={8} cy={8} r={8} fill="url(#fanclub_svg__a)" />
        <path
            fill="#fff"
            d="M7.57 3.73a.5.5 0 0 1 .86 0l1.088 1.842a.5.5 0 0 0 .322.234l2.088.465a.5.5 0 0 1 .266.819l-1.416 1.603a.5.5 0 0 0-.123.379l.203 2.13a.5.5 0 0 1-.696.506l-1.963-.852a.5.5 0 0 0-.398 0l-1.963.852a.5.5 0 0 1-.696-.506l.203-2.13a.5.5 0 0 0-.123-.379L3.806 7.09a.5.5 0 0 1 .266-.82l2.088-.464a.5.5 0 0 0 .322-.234z"
        />
        <defs>
            <linearGradient
                id="fanclub_svg__a"
                x1={12.5}
                x2={3.5}
                y1={14.5}
                y2={1.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#090" />
                <stop offset={1} stopColor="#3FD43F" />
            </linearGradient>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgFanclub)
const Memo = memo(ForwardRef)
export default Memo
