import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react"
import { useUrlStateContext } from "../../../../../hooks/urlStateContext"
import { error } from "../../../../../utils/debug"
import { searchSpokenLanguages } from "../../../LanguageFilterSection"
import {
    loadPopularitySortedTags,
    loadRoomlistFilteredTags,
} from "../filterSections/hashtagSection/hashtagApiUtils"
import { ActiveFiltersSection } from "../types"
import type { ILanguage } from "../../../LanguageFilterSection"

export interface IFiltersContext {
    overlayedSection: ActiveFiltersSection
    setOverlayedSection: (section: ActiveFiltersSection) => void
    topTags: string[]
    errorTopTags: string | null
    setErrorTopTags: (error: string | null) => void
    tagSearchInput: string
    setTagSearchInput: (textInput: string) => void
    languageSearchInput: string
    setLanguageSearchInput: (textInput: string) => void
    languageOptions: ILanguage[]
    loadingLanguageOptions: boolean
    errorLanguageOptions: string | null
    setErrorLanguageOptions: (error: string | null) => void
}

export const FiltersContext = createContext<IFiltersContext | undefined>(
    undefined,
)

interface FiltersContextProviderProps {
    initialOverlayedSection?: ActiveFiltersSection
    initialTagSearchInput?: string
    initialLanguageSearchInput?: string
    children: React.ReactNode
}

export function FiltersContextProvider({
    initialOverlayedSection = ActiveFiltersSection.None,
    initialTagSearchInput = "",
    initialLanguageSearchInput = "",
    children,
}: FiltersContextProviderProps) {
    const [overlayedSection, setOverlayedSection] =
        useState<ActiveFiltersSection>(initialOverlayedSection)
    const [topTags, setTopTags] = useState<string[]>([]) // uses roomlistFilteredTags and popularitySortedTags to get a list containing roomlist filtered tags sorted by hitcount
    const [errorTopTags, setErrorTopTags] = useState<string | null>(null)
    const [tagSearchInput, setTagSearchInput] = useState<string>(
        initialTagSearchInput,
    )
    const [roomlistFilteredTags, setRoomlistFilteredTags] = useState<string[]>(
        [],
    )
    const [popularitySortedTags, setPopularitySortedTags] = useState<string[]>(
        [],
    )
    const urlStateContext = useUrlStateContext()

    // LANGUAGE OPTIONS SECTION
    const [languageOptions, setLanguageOptions] = useState<ILanguage[]>([])
    const [loadingLanguageOptions, setLoadingLanguageOptions] =
        useState<boolean>(true)
    const [errorLanguageOptions, setErrorLanguageOptions] = useState<
        string | null
    >(null)
    const [languageSearchInput, setLanguageSearchInput] = useState<string>(
        initialLanguageSearchInput,
    )

    const fetchTopTags = useCallback(
        async (fetchPopularitySortedTags = false) => {
            setErrorTopTags(null)
            try {
                const tagApiLoads: Promise<string[]>[] = [
                    loadRoomlistFilteredTags(),
                ]

                if (fetchPopularitySortedTags) {
                    tagApiLoads.push(loadPopularitySortedTags())
                }

                const results = await Promise.all(tagApiLoads)
                setRoomlistFilteredTags(results[0])

                if (tagApiLoads.length > 1) {
                    setPopularitySortedTags(results[1])
                }
            } catch (err) {
                error(err)
                setErrorTopTags("Failed to load top tags")
            }
        },
        [],
    )

    useEffect(() => {
        setTopTags(
            popularitySortedTags.filter((tag) =>
                roomlistFilteredTags.includes(tag),
            ),
        )
    }, [popularitySortedTags, roomlistFilteredTags])

    useEffect(() => {
        void fetchTopTags()
    }, [
        urlStateContext.regions,
        urlStateContext.ageMin,
        urlStateContext.ageMax,
        urlStateContext.privatePrices,
        urlStateContext.spyShowPrices,
        urlStateContext.roomSize,
        urlStateContext.spokenLanguages,
        urlStateContext.apps,
        urlStateContext.aspApp,
        urlStateContext.keywords,
        urlStateContext.sort,
        fetchTopTags,
    ])

    useEffect(() => {
        // Popularity sorted tags are only filtered on gender, so we only need to trigger updates from this particular
        // API when there are explicit updates to genders on the frontend.
        void fetchTopTags(true)
    }, [
        urlStateContext.genders,
        urlStateContext.pageType,
        urlStateContext.showType,
        fetchTopTags,
    ])

    const fetchLanguageOptions = useCallback(async () => {
        setLoadingLanguageOptions(true)
        setErrorLanguageOptions(null)
        try {
            const languageOptions = await searchSpokenLanguages({
                sort: "room_count",
            })
            setLanguageOptions(languageOptions)
        } catch (err) {
            error(err)
            setErrorLanguageOptions("Failed to load language options")
        } finally {
            setLoadingLanguageOptions(false)
        }
    }, [])

    useEffect(() => {
        void fetchLanguageOptions()
    }, [urlStateContext.genders, fetchLanguageOptions])

    const contextValue = {
        overlayedSection,
        setOverlayedSection,
        topTags,
        errorTopTags,
        setErrorTopTags,
        tagSearchInput,
        setTagSearchInput,
        languageOptions,
        loadingLanguageOptions,
        errorLanguageOptions,
        setErrorLanguageOptions,
        languageSearchInput,
        setLanguageSearchInput,
    }

    return (
        <FiltersContext.Provider value={contextValue}>
            {children}
        </FiltersContext.Provider>
    )
}

export function useFiltersContext(): IFiltersContext {
    const context = useContext(FiltersContext)
    if (!context) {
        throw new Error(
            "useFiltersContext must be used within a FiltersProvider",
        )
    }
    return context
}
