// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MLzs57yp58QJmyJVCoex{position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.5);display:flex;justify-content:center;align-items:center;z-index:1000}.A1e76ZNhixhxfAxdqGmw{background-color:#fff;border-radius:16px;padding:24px;max-width:90%;max-height:90%;overflow:auto;margin:0 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `MLzs57yp58QJmyJVCoex`,
	"modalContent": `A1e76ZNhixhxfAxdqGmw`
};
export default ___CSS_LOADER_EXPORT___;
