import type { ComponentPropsWithoutRef } from "react"
import { useEffect, useState } from "react"
import "./MobileAutoRefillToggle.scss"
import { t } from "@lingui/macro"
import {
    useCreateAutoRefilSettingsMutation,
    useLazyGetAutoRefilSettingsQuery,
    useUpdateAutoRefilSettingsMutation,
} from "../../../store/purchasePage/purchasePageSlice"
import { showToast } from "../../../utils/sharedToast"
import { InputToggle } from "../../common/atoms/InputToggle"

interface MobileAutoRefillToggleProps extends ComponentPropsWithoutRef<"div"> {
    hidden: boolean
}

export function MobileAutoRefillToggle({
    hidden,
    ...props
}: MobileAutoRefillToggleProps) {
    const [isActive, setIsActive] = useState(false)

    const [getAutoRefillSettings, { isLoading, isFetching }] =
        useLazyGetAutoRefilSettingsQuery()

    const [createAutoRefillSettings, { isLoading: isCreating }] =
        useCreateAutoRefilSettingsMutation()
    const [updateAutoRefillSettings, { isLoading: isUpdating }] =
        useUpdateAutoRefilSettingsMutation()

    const [hadAutoRefillSettings, setHadAutoRefillSettings] = useState(false)

    const handleAutoRefillToggleClick = async (val: boolean) => {
        // call api to enable/disable auto refill
        setIsActive(val)
        const funcToUse = hadAutoRefillSettings
            ? updateAutoRefillSettings
            : createAutoRefillSettings
        await funcToUse({ is_active: val })
            .unwrap()
            .then((res) => {
                if (!res.success) {
                    setIsActive(!val)
                    // show toast
                    showToast({
                        message: t`Failed to update Auto Refill settings`,
                    })
                    return
                }
                if (!hadAutoRefillSettings) {
                    setHadAutoRefillSettings(true)
                }
                showToast({
                    message: val
                        ? t`Auto Refill enabled`
                        : t`Auto Refill disabled`,
                })
            })
            .catch(() => {
                setIsActive(!val)
                // show toast
                showToast({
                    message: t`Failed to update Auto Refill settings`,
                })
            })
    }

    useEffect(() => {
        if (hidden) {
            return
        }
        const fetchAutoRefillSettings = async () => {
            await getAutoRefillSettings()
                .unwrap()
                .then((res) => {
                    const isAutoRefillEnabled =
                        Boolean(res.success) && Boolean(res.is_active)
                    setIsActive(isAutoRefillEnabled)

                    const hadAutoRefillSettings = Boolean(res.plan)
                    setHadAutoRefillSettings(hadAutoRefillSettings)
                })
                .catch(() => {
                    setIsActive(false)
                })
        }

        void fetchAutoRefillSettings()
    }, [getAutoRefillSettings, hidden])

    if (hidden) {
        return null
    }

    return (
        <div className="MobileAutoRefillToggle" {...props}>
            <div>{t`Tokens Auto Refill`}</div>
            <InputToggle
                onToggle={(v) => void handleAutoRefillToggleClick(v)}
                checked={isActive}
                disabled={isLoading || isFetching || isCreating || isUpdating}
            />
        </div>
    )
}
