import type { ComponentPropsWithoutRef } from "react"
import "./TokenCount.scss"
import { Tokens } from "../../common/atoms/Icons/Chat"

interface TokenCountProps extends ComponentPropsWithoutRef<"div"> {
    tokenBalance: number
}

export function TokenCount({ tokenBalance = 0, ...props }: TokenCountProps) {
    return (
        <div {...props} className="TokenCount">
            <Tokens className="TokenCount__icon" width={18} height={18} />
            <span className="TokenCount__number">{tokenBalance}</span>
        </div>
    )
}
