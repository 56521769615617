import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCbBrandIcon = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 40 40"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect
            width={40}
            height={40}
            fill="url(#cbBrandIcon_svg__a)"
            rx={7.32}
        />
        <rect
            width={39.739}
            height={39.739}
            x={0.131}
            y={0.131}
            stroke="url(#cbBrandIcon_svg__b)"
            strokeOpacity={0.15}
            strokeWidth={0.261}
            rx={7.19}
        />
        <path
            fill="url(#cbBrandIcon_svg__c)"
            d="M23.42 30.697c-4.553 0-7.504-3.443-7.504-8.76 0-.62.05-1.266.14-1.924-5.26-.658-8.35-2.468-8.35-4.962 0-1.646 1.324-3.089 4.061-4.405l1.766-.861.075 1.974c.089 2.545 1.917 3.773 3.494 4.368C18.729 12.317 21.705 10 25.098 10c3.594 0 5.196 2.013 5.196 4.013 0 2.608-2.724 5.456-7.933 6.101-.416 1.038-.656 2.102-.656 2.95 0 1.62.732 1.62 1.173 1.62.618 0 2.106-1.253 2.333-2.646l.177-1.088h1.097c2.12 0 3.33 1.375 3.33 3.375 0 2.788-2.41 6.372-6.395 6.372"
        />
        <path
            fill="#FDB915"
            fillRule="evenodd"
            d="M26.46 22.266c-.302 1.887-2.22 3.722-3.594 3.722-1.766 0-2.447-1.266-2.46-2.899 0-1.304.43-2.835 1.073-4.164 5.044-.393 7.516-2.874 7.516-4.9 0-1.468-1.324-2.721-3.922-2.721-3.506 0-6.067 2.911-7.214 6.405-3.115-.734-5.449-2.721-5.562-5.899-2.333 1.14-3.33 2.253-3.33 3.254 0 1.936 3.658 3.43 8.577 3.823-.227 1-.354 2.063-.354 3.063 0 3.95 1.88 7.469 6.218 7.469 3.204 0 5.26-3.14 5.26-5.127 0-1.266-.77-2.026-2.208-2.026m-1.034-5.835c0 .57-.883 1.367-3.405 1.557.781-1.253 1.753-2.14 2.673-2.14.43 0 .732.266.732.583"
            clipRule="evenodd"
        />
        <g fill="#fff" opacity={0.4}>
            <path
                d="M23.345 26.924c1.576 0 3.456-1.81 3.973-3.709.277.038.517.114.706.228-.214-.392-.605-.62-1.173-.696-.517 1.899-2.396 3.71-3.973 3.71-.719 0-1.311-.19-1.778-.533.492.646 1.249 1 2.245 1M25.804 16c.064.126.101.278.101.43 0 .418-.24.86-.832 1.228a9 9 0 0 0 1.286-.633c0-.038.013-.089.013-.127 0-.38-.227-.709-.568-.899M18.136 22.406c0-.937.114-1.95.34-2.962a.5.5 0 0 0-.075-.38.45.45 0 0 0-.34-.19h-.038c0 .038 0 .076-.013.101-.227 1-.34 2.026-.34 2.962 0 2.153.605 4.393 2.144 5.748-1.186-1.367-1.678-3.368-1.678-5.28M9.926 15.519c0-.71.744-1.532 2.131-2.33-.05-.215-.1-.43-.139-.658-1.589.86-2.459 1.747-2.459 2.52 0 .442.278.873.77 1.265q-.303-.38-.303-.797M17.38 18.38c.075.012.138.012.214.012.113.013.202.05.29.127.113.038.24.063.353.101.038.013.076.013.114.013a.46.46 0 0 0 .441-.33c.971-2.936 3.254-6.088 6.773-6.088 1.122 0 2.03.253 2.636.709-.53-.735-1.627-1.178-3.103-1.178-3.519 0-5.801 3.14-6.773 6.09a.47.47 0 0 1-.441.328c-.038 0-.076 0-.114-.012-1.589-.38-2.913-1.064-3.897-1.962.858.962 2.043 1.721 3.507 2.19"
                opacity={0.4}
            />
        </g>
        <defs>
            <linearGradient
                id="cbBrandIcon_svg__a"
                x1={20}
                x2={20}
                y1={0}
                y2={40}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFF0D5" />
                <stop offset={1} stopColor="#FDFDFD" />
            </linearGradient>
            <linearGradient
                id="cbBrandIcon_svg__b"
                x1={20}
                x2={20}
                y1={0}
                y2={40}
                gradientUnits="userSpaceOnUse"
            >
                <stop />
                <stop offset={1} stopColor="#666" />
            </linearGradient>
            <linearGradient
                id="cbBrandIcon_svg__c"
                x1={19.001}
                x2={19.001}
                y1={-26.085}
                y2={6.283}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#275A81" />
                <stop offset={1} stopColor="#0A1925" />
            </linearGradient>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgCbBrandIcon)
const Memo = memo(ForwardRef)
export default Memo
