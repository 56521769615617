import { useEffect, useRef } from "react"

interface LongPressOptions {
    onLongPress: () => void
    onPressRelease?: () => void
    delay?: number
}

export function useLongPress({
    onLongPress,
    onPressRelease,
    delay = 500,
}: LongPressOptions): {
    ref: React.RefObject<HTMLDivElement>
} {
    const timerRef = useRef<NodeJS.Timeout | null>(null)
    const elementRef = useRef<HTMLDivElement | null>(null)

    const start = () => {
        timerRef.current = setTimeout(() => {
            onLongPress()
        }, delay)
    }

    const clear = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
            timerRef.current = null
        }
        if (onPressRelease) {
            onPressRelease()
        }
    }

    useEffect(() => {
        const element = elementRef.current
        if (!element) return

        const handleTouchStart = (e: TouchEvent) => {
            e.preventDefault()
            start()
        }

        const handleTouchEnd = () => {
            clear()
        }

        element.addEventListener("touchstart", handleTouchStart, {
            passive: false,
        })
        element.addEventListener("touchend", handleTouchEnd)

        return () => {
            element.removeEventListener("touchstart", handleTouchStart)
            element.removeEventListener("touchend", handleTouchEnd)
        }
    }, [onLongPress, onPressRelease, delay])

    return {
        ref: elementRef,
    }
}
