import "../filterSection.scss"
import {
    isPrivateSpyBadgesActive,
    PrivatePrices,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../../hooks/urlStateContext"
import { FilterSection } from "../FilterSection"

export const privatePriceToButtonText = new Map<PrivatePrices, string>([
    [PrivatePrices.Value6, "6 tks"],
    [PrivatePrices.Range12to18, "12 - 18 tks"],
    [PrivatePrices.Range30to42, "30 - 42 tks"],
    [PrivatePrices.Range60to72, "60 - 72 tks"],
    [PrivatePrices.Range90Plus, "90+ tks"],
])

export const PrivatePriceSection = () => {
    const { privatePrices } = useUrlStateContext()
    const privateSpyBadgeActive = isPrivateSpyBadgesActive()
    const clearButtonText = privateSpyBadgeActive ? "Clear all" : "Clear"
    return (
        <FilterSection
            isClearDisabled={(privatePrices ?? []).length === 0}
            title="Private Show (tokens per minute)"
            testId="private-prices-mobile-filters-section"
            onClear={() => {
                if ((privatePrices ?? []).length === 0) {
                    return
                }
                UrlState.current.setPartialState({ privatePrices: undefined })
            }}
            clearButtonText={clearButtonText}
        >
            <PrivatePriceSectionContent />
        </FilterSection>
    )
}

export const PrivatePriceSectionContent = ({
    combinedOverlay = false,
}: {
    combinedOverlay?: boolean | undefined
}) => {
    const { privatePrices } = useUrlStateContext()
    const privateSpyBadgeActive = isPrivateSpyBadgesActive()

    return (
        <div
            className={`FilterSection__filterOptions${combinedOverlay ? "Combined" : ""}`}
        >
            {Object.entries(PrivatePrices).map(([key, privatePrice]) => (
                <button
                    key={key}
                    data-testid={`private-prices-filter-option-${privatePrice}`}
                    className={`FilterSection__filterOption ${
                        privatePrices?.includes(privatePrice) ? "active" : ""
                    }`}
                    onClick={() =>
                        UrlState.current.setPartialState({
                            privatePrices: privatePrices?.includes(privatePrice)
                                ? privatePrices.filter(
                                      (item) => item !== privatePrice,
                                  )
                                : privateSpyBadgeActive
                                  ? [...(privatePrices || []), privatePrice]
                                  : [privatePrice],
                        })
                    }
                >
                    {privatePriceToButtonText.get(privatePrice)}
                </button>
            ))}
        </div>
    )
}
