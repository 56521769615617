import { RecordStart, RecordStop } from "../../common/atoms/Icons/Others"
import { StylelessButton } from "../../common/atoms/StylelessButton"

interface RecordButtonProps {
    onClick: () => void
    isStreaming: boolean
}

export function RecordButton({ onClick, isStreaming }: RecordButtonProps) {
    const size = "42px"
    const icon = isStreaming ? (
        <RecordStop height={size} width={size} />
    ) : (
        <RecordStart height={size} width={size} />
    )

    return <StylelessButton onClick={onClick}>{icon}</StylelessButton>
}
