import { useEffect } from "react"
import { useAppDispatch } from "../hooks"
import {
    setRoomSubject,
    updateRoomPrivateRequestStatus,
    updateRoomStatus,
} from "../roomSlice"
import type {
    MobileBroadcastTopics,
    RoomBroadcasterPrivateStatusTopic,
    RoomStatusTopic,
    RoomTitleChangeTopic,
} from "../../components/mobile_broadcast/types"

export const useRoomStatusEffect = (
    topics: MobileBroadcastTopics,
    roomUid: string | undefined,
): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const dispatchRoomStatusUpdate = (event: RoomStatusTopic) => {
            dispatch(updateRoomStatus(event.status))
        }

        if (roomUid !== undefined) {
            const roomStatusUpdateTopic = new topics.RoomStatusTopic(roomUid)
            roomStatusUpdateTopic.onMessage.listen(dispatchRoomStatusUpdate)
            return () =>
                roomStatusUpdateTopic.onMessage.removeListener(
                    dispatchRoomStatusUpdate,
                )
        }
    }, [roomUid])
}

export const useRoomSubjectPushEffect = (
    topics: MobileBroadcastTopics,
    roomUid?: string,
): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const dispatchSubjectChange = (event: RoomTitleChangeTopic) => {
            dispatch(setRoomSubject(event.title))
        }

        if (roomUid !== undefined) {
            const roomSubjectTopic = new topics.RoomTitleChangeTopic(roomUid)
            roomSubjectTopic.onMessage.listen(dispatchSubjectChange)
            return () =>
                roomSubjectTopic.onMessage.removeListener(dispatchSubjectChange)
        }
    }, [roomUid])
}

export const usePrivateRequestStatusEffect = (
    topics: MobileBroadcastTopics,
    roomUid: string | undefined,
): void => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        const dispatchPrivateRequestStatus = (
            event: RoomBroadcasterPrivateStatusTopic,
        ) => {
            dispatch(updateRoomPrivateRequestStatus(event))
        }

        if (roomUid !== undefined) {
            const privateRequestStatusTopic =
                new topics.RoomBroadcasterPrivateStatusTopic(roomUid)
            privateRequestStatusTopic.onMessage.listen(
                dispatchPrivateRequestStatus,
            )
            return () =>
                privateRequestStatusTopic.onMessage.removeListener(
                    dispatchPrivateRequestStatus,
                )
        }
    }, [roomUid])
}
