import type React from "react"
import { forwardRef } from "react"
import { Sizes } from "../../../../interfaces/sizing"
import { mergeClasses } from "../../../../utils/css"
import "./Textarea.scss"

interface TextareaProps extends React.ComponentPropsWithRef<"textarea"> {
    /**
     * Apply any additional classes in addition to the ones provided by this component
     */
    className?: string
    /**
     * Defines the font size and line height
     */
    fontSize?: keyof typeof Sizes
}

/**
 * `<Textarea />` should be used in mostly the same way as `<textarea>`. It provides some light
 * default styling, and some props to control sizing, and exposes a ref and all other input props so that
 * it can work with any form library.
 */
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ fontSize = Sizes.sm, className, ...props }, ref) => {
        return (
            <textarea
                className={mergeClasses(
                    "Textarea",
                    `Textarea--${fontSize}`,
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    },
)
