import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgBellStrikethrough = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 20"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#6E6F70"
            d="M3.4 16.6a.9.9 0 1 1 0-1.8H4v-6q0-1.024.338-1.962.337-.938.912-1.763L8.975 8.8H6.4L.887 3.287a.91.91 0 1 1 1.288-1.286l15.663 15.686a.902.902 0 0 1-1.276 1.275L14.2 16.6zM16 13.3 6.575 3.85a5.2 5.2 0 0 1 1.063-.575Q8.2 3.05 8.8 2.925V1.6q0-.5.35-.85T10 .4t.85.35.35.85v1.325q2.125.4 3.463 2.05Q16 6.625 16 8.8zm-6 6.3q-.75 0-1.275-.525A1.74 1.74 0 0 1 8.2 17.8h3.6q0 .75-.525 1.275T10 19.6"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgBellStrikethrough)
const Memo = memo(ForwardRef)
export default Memo
