import type React from "react"
import { forwardRef } from "react"
import { VariableSizeList } from "react-window"
import type {
    ListChildComponentProps,
    VariableSizeListProps,
} from "react-window"

/**
 * This is a wrapper component around react-window's VariableSizeList to fix type compatibility issues with React 18.
 */
type VirtualListProps<T> = VariableSizeListProps<T> & {
    children: React.ComponentType<ListChildComponentProps<T>>
}

function VirtualListComponent<T>(
    { children, ...rest }: VirtualListProps<T>,
    ref: React.Ref<VariableSizeList>,
) {
    const Component =
        VariableSizeList as unknown as React.JSXElementConstructor<any>
    return (
        <Component {...rest} ref={ref}>
            {children}
        </Component>
    )
}

export const VirtualList = forwardRef(VirtualListComponent) as <T>(
    props: VirtualListProps<T> & { ref?: React.Ref<VariableSizeList> },
) => React.ReactElement
