import "./CloseButton.scss"
import type { ComponentPropsWithRef } from "react"
import { mergeClasses } from "../../../../utils/css"
import { Close } from "../Icons/Others"

interface CloseButtonProps extends ComponentPropsWithRef<"button"> {
    onClick: () => void
    size: string // value for svg width/height attributes
}

export function CloseButton({ onClick, size, ...props }: CloseButtonProps) {
    return (
        <button
            onClick={onClick}
            data-testid="close-button"
            {...props}
            className={mergeClasses("cbr-close-button", props.className)}
        >
            <Close width={size} height={size} className="CloseIcon" />
        </button>
    )
}
