import { Trans } from "@lingui/macro"
import { Typography } from "../../common"
import { RotateDevice } from "../../common/atoms/Icons/Others"
import "./RotatePrompt.scss"

export function RotatePrompt() {
    return (
        <div className="RotatePrompt" data-testid="rotate-prompt">
            <div className="RotatePrompt__content">
                <RotateDevice
                    className="RotatePrompt__icon"
                    width="102"
                    height="87"
                />
                <Typography size="mdpx" className="RotatePrompt__line1">
                    <Trans>
                        Your broadcast may appear sideways to viewers. Rotate
                        your device into landscape mode.
                    </Trans>
                </Typography>
                <Typography size="mdpx" className="RotatePrompt__line2">
                    <Trans>
                        Portrait mode broadcasting and many more mobile
                        broadcasting improvements are coming soon!
                    </Trans>
                </Typography>
            </div>
        </div>
    )
}
