import type React from "react"
import "./filterSection.scss"

interface FilterSectionProps {
    title: string
    onClear: () => void
    isClearDisabled?: boolean
    testId?: string
    clearButtonText: string
    children: React.ReactNode
}

export const FilterSection: React.FC<FilterSectionProps> = ({
    title,
    onClear,
    isClearDisabled = false,
    testId,
    clearButtonText,
    children,
}) => {
    const getTestId = (title: string) => {
        return (
            testId ??
            `${title.toLowerCase().replaceAll(" ", "-")}-mobile-filters-section`
        )
    }
    return (
        <div data-testid={getTestId(title)}>
            <div className="FilterSection__header">
                <span className="FilterSection__header-text">{title}</span>
                <button
                    onClick={onClear}
                    className={`FilterSection__clear-button${
                        isClearDisabled
                            ? " FilterSection__clear-button-disabled"
                            : ""
                    }`}
                    data-testid="mobile-filters-section-clear-button"
                >
                    {clearButtonText}
                </button>
            </div>
            {children}
        </div>
    )
}
