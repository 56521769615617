import { useState } from "react"
import "./MessageHeaderMenu.scss"
import { useOutsideClick } from "../../../hooks/useOutsideClick"
import { IconButton } from "../common/IconButton/IconButton"
import { classNames, getMobileSvgPath } from "../utils"
import styles from "./MessageHeaderMenu.module.scss"

type MenuItem = {
    icon: string
    text: string
    onClick: () => void
    className?: string
}

const MenuItem = ({ icon, text, onClick, className }: MenuItem) => (
    <div onClick={onClick} className={classNames("msg-menu-item", className)}>
        <img src={icon} />
        <span>{text}</span>
    </div>
)

export function MessageHeaderMenu() {
    const [isOpen, setIsOpen] = useState(false)
    const ref = useOutsideClick(() => setIsOpen(false))

    const toggleMenu = () => {
        setIsOpen((prev) => !prev)
    }

    return (
        <div
            ref={ref}
            style={{ position: "relative", display: "inline-block" }}
        >
            <IconButton
                icon={getMobileSvgPath("ellipsis-vertical")}
                iconAlt="Menu"
                onClick={toggleMenu}
                className={classNames({ open: isOpen })}
            />
            {isOpen && (
                <div className="msg-header-menu">
                    <MenuItem
                        icon={getMobileSvgPath("edit-note")}
                        text="Note"
                        onClick={() => {}}
                    />
                    <MenuItem
                        icon={getMobileSvgPath("mute")}
                        text="Mute"
                        onClick={() => {}}
                    />
                    <div className={styles.divider} />
                    <MenuItem
                        icon={getMobileSvgPath("trash")}
                        text="Delete Conversation"
                        onClick={() => {}}
                        className="red"
                    />
                </div>
            )}
        </div>
    )
}
