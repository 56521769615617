import type React from "react"
import { useCallback, useEffect, useState } from "react"
import { Toast } from "./Toast"

interface ToastProps {
    message: string
    position: "top" | "bottom"
}

type ToastManagerApi = {
    fire: (props: ToastProps) => void
}

let toastManagerApi: ToastManagerApi | null = null

export const ToastManager: React.FC = () => {
    const [toast, setToast] = useState<ToastProps | null>(null)

    const handleClose = () => setToast(null)
    const fire = useCallback((props: ToastProps) => {
        setToast(props)
    }, [])

    useEffect(() => {
        toastManagerApi = { fire }
        return () => {
            toastManagerApi = null
        }
    }, [fire])

    return toast ? (
        <Toast
            message={toast.message}
            position={toast.position}
            onClose={handleClose}
        />
    ) : null
}

export const toast = {
    fire: (props: ToastProps) => {
        if (!toastManagerApi) {
            return
        }
        toastManagerApi.fire(props)
    },
}
