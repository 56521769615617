import { type ChangeEvent, useState } from "react"
import "./NPSSentimentSurvey.scss"
import { gettext } from "@multimediallc/web-utils"
import { Sizes, Weights } from "../../../interfaces/sizing"
import {
    Button,
    CancelConfirmButtonPair,
    Textarea,
    Typography,
} from "../../common"

const i18n = {
    sureIllGiveFeedback: gettext("Sure, I'll give feedback"),
    noThanks: gettext("No thanks"),
    helpMakeChaturbate: gettext(
        "Help make Chaturbate better by completing a 2-question survey.",
    ),
    howLikely: gettext(
        "How likely are you to recommend Chaturbate to someone seeking an adult website?",
    ),
    haveYouUsed: gettext("Have you used another cam site this week?"),
    yes: gettext("Yes"),
    no: gettext("No"),
    veryLikely: gettext("Very likely"),
    notLikely: gettext("Not likely"),
    thanks: gettext("Thanks for your response!"),
    whatInfluenced: gettext("What influenced your rating?"),
    submit: gettext("Submit"),
    cancel: gettext("Cancel"),
    submitAndClose: gettext("Submit and close"),
}
interface NPSSentimentSurveyProps {
    onSubmit: (resp: string[], prompts: string[]) => void
    closeModal: () => void
}

export function NPSSentimentSurvey({
    onSubmit,
    closeModal,
}: NPSSentimentSurveyProps) {
    const [step, setStep] = useState(1)
    const [rating, setRating] = useState<string | undefined>()
    const [usedOtherCamSite, setUsedOtherCamsite] = useState<
        string | undefined
    >()
    const [whatInfluenced, setWhatInfluenced] = useState<string>("")

    const nextDisabled = rating === undefined || usedOtherCamSite === undefined

    return (
        <div className="NPSSentimentSurvey">
            {step === 1 && (
                <>
                    <Typography size={Sizes.xs} className="helpMakeText">
                        {i18n.helpMakeChaturbate}
                    </Typography>

                    <CancelConfirmButtonPair
                        actionClickHandler={() => setStep((prev) => prev + 1)}
                        cancelClickHandler={closeModal}
                        actionText={i18n.sureIllGiveFeedback}
                        cancelText={i18n.noThanks}
                    />
                </>
            )}
            {step === 2 && (
                <>
                    <Typography
                        className="NPSSentimentSurvey__title"
                        size={Sizes.xs}
                        weight={Weights.bold}
                    >
                        {i18n.howLikely}
                    </Typography>

                    <div className="ratingContainer">
                        <div className="ratingContainer__buttons">
                            {[
                                "0",
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                                "10",
                            ].map((num) => (
                                <Button
                                    key={num}
                                    className={`ratingButton${
                                        rating === num ? " selected" : ""
                                    }`}
                                    onClick={() => setRating(num)}
                                    text={num.toString()}
                                />
                            ))}
                        </div>

                        <div className="ratingContainer__helperText">
                            <Typography size={Sizes.xs}>
                                {i18n.notLikely}
                            </Typography>
                            <Typography size={Sizes.xs}>
                                {i18n.veryLikely}
                            </Typography>
                        </div>
                    </div>
                    <Typography
                        className="NPSSentimentSurvey__title"
                        size={Sizes.xs}
                        weight={Weights.bold}
                    >
                        {i18n.haveYouUsed}
                    </Typography>

                    <div className="ratingContainer__yesNo">
                        {[i18n.yes, i18n.no].map((val) => (
                            <Button
                                key={val}
                                className={`ratingButton valueButton${
                                    usedOtherCamSite === val ? " selected" : ""
                                }`}
                                onClick={() => setUsedOtherCamsite(val)}
                                text={val}
                            />
                        ))}
                    </div>

                    <CancelConfirmButtonPair
                        actionClickHandler={() => setStep((prev) => prev + 1)}
                        cancelClickHandler={closeModal}
                        actionText={i18n.submit}
                        cancelText={i18n.cancel}
                        actionProps={{ disabled: nextDisabled }}
                    />
                </>
            )}
            {step === 3 && (
                <>
                    <Typography
                        className="NPSSentimentSurvey__title__12"
                        size={Sizes.xs}
                        weight={Weights.bold}
                    >
                        {i18n.thanks}
                    </Typography>

                    <Typography
                        className="NPSSentimentSurvey__title optional"
                        size={Sizes.xs}
                        weight={Weights.bold}
                    >
                        {i18n.whatInfluenced}
                        <span>(Optional)</span>
                    </Typography>
                    <Textarea
                        aria-label="Optional feedback"
                        value={whatInfluenced}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            setWhatInfluenced(e.target.value)
                        }
                        style={{ resize: "none" }}
                        // backend limit is 5000 characters, accounting for new lines etc
                        maxLength={4950}
                    />

                    <Button
                        className="finalSubmit"
                        text={i18n.submitAndClose}
                        onClick={() =>
                            onSubmit(
                                [
                                    rating ?? "",
                                    usedOtherCamSite ?? "",
                                    whatInfluenced
                                        ? // cleanup new lines from textarea
                                          whatInfluenced.replace(
                                              /(?:\r\n|\r|\n)/g,
                                              "",
                                          )
                                        : "",
                                ],
                                [
                                    i18n.howLikely,
                                    i18n.haveYouUsed,
                                    i18n.whatInfluenced,
                                ],
                            )
                        }
                    />
                </>
            )}
        </div>
    )
}
