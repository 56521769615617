import type { FormEvent } from "react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useAppSelector } from "../../../store/hooks"
import { usePublishMessageMutation } from "../../../store/messagingSlice"
import { debug } from "../../../utils/debug"
import { getMobileSvgPath } from "../utils"
import "./MessageInputBar.scss"

export function MessageInputBar() {
    const { username } = useParams<{ username: string }>()
    const me = useAppSelector((state) => state.user.loggedInUser)
    const [publishMessage] = usePublishMessageMutation()
    const [text, setText] = useState("")
    const showSend = text.trim().length > 0

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        setText("")
        try {
            if (!username || !me) throw new Error("No from_user or to_user")
            publishMessage({
                message: text,
                to_user: username,
                from_user: me?.username,
            })
        } catch (e) {
            debug(e)
        }
    }

    return (
        <div className="messages-input">
            <button>
                <img src={getMobileSvgPath("camera")} alt="Camera" />
            </button>
            <button>
                <img src={getMobileSvgPath("gallery")} alt="Gallery" />
            </button>
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <input
                        type="text"
                        placeholder="Send a message..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                    <img src={getMobileSvgPath("smile")} alt="Smile" />
                </div>
                {showSend ? (
                    <button type="submit">
                        <img
                            src={getMobileSvgPath("send-arrow")}
                            alt="Send arrow"
                        />
                    </button>
                ) : (
                    <button>
                        <img src={getMobileSvgPath("tokens")} alt="Tokens" />
                    </button>
                )}
            </form>
        </div>
    )
}
