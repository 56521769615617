import { t } from "@lingui/macro"
import { ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import {
    UrlStateContextProvider,
    useUrlStateContext,
} from "../../../../hooks/urlStateContext"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"
import "./MobileFollowingNav.scss"

export function MobileFollowingNav() {
    return (
        <UrlStateContextProvider>
            <MobileFollowingNavContent />
        </UrlStateContextProvider>
    )
}

export function MobileFollowingNavContent() {
    const { showType } = useUrlStateContext()
    if (showType !== ShowType.FOLLOW && showType !== ShowType.FOLLOW_OFFLINE) {
        return null
    }

    const getHref = (offline: boolean): string =>
        UrlState.current.getURLForPartialState(getNewState(offline))

    const onOptionClick = (e: React.MouseEvent, offline: boolean) => {
        if (e.ctrlKey || e.metaKey || e.shiftKey) {
            return
        }
        e.preventDefault()
        UrlState.current.setPartialState(getNewState(offline))
    }

    const isOffline = showType === ShowType.FOLLOW_OFFLINE
    return (
        <div className="MobileFollowingNav" data-testid="mobile-following-nav">
            <a
                className={`followLink ${!isOffline ? "active" : ""}`}
                href={getHref(false)}
                onClick={(e) => onOptionClick(e, false)}
                data-testid="online-filter-link"
            >
                {t`Online Rooms`}
            </a>
            <a
                className={`followLink ${isOffline ? "active" : ""}`}
                href={getHref(true)}
                onClick={(e) => onOptionClick(e, true)}
                data-testid="offline-filter-link"
            >
                {t`Offline Rooms`}
            </a>
        </div>
    )
}

function getNewState(offline: boolean): Partial<IURLState> {
    return {
        showType: offline ? ShowType.FOLLOW_OFFLINE : ShowType.FOLLOW,
    }
}
