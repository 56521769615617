import { UserName } from "../UserName"
import type { IUserInfo } from "../../../../interfaces/userMenu"

type UserListProps = {
    roomUsers: IUserInfo[]
    createContextMenu: (user: IUserInfo, element: HTMLElement) => void
}

export function UserList(props: UserListProps) {
    return (
        <>
            {props.roomUsers.map((user) => (
                <UserName
                    key={user.username}
                    user={user}
                    openMenu={(element) => {
                        props.createContextMenu(user, element)
                    }}
                />
            ))}
        </>
    )
}
