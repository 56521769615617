import type { ComponentPropsWithoutRef } from "react"
import { t, Trans } from "@lingui/macro"
import { Sizes } from "../../../interfaces/sizing"
import { colorClass } from "../../../utils/css"
import { Button, ButtonSize, Typography } from "../../common"
import { Overlay } from "../../common/atoms/Overlay"
import "./BroadcasterAgreementModal.scss"

type BroadcasterAgreementModalProps = ComponentPropsWithoutRef<"div"> & {
    onAgreement: () => void
}

export const mobileBroadcastTermsAgreed = "mobileBroadcastTermsAgreed"

export const hasBroadcasterAgreed = (): boolean => {
    return localStorage.getItem(mobileBroadcastTermsAgreed) === "true"
}

export function BroadcasterAgreementModal({
    onAgreement,
    ...props
}: BroadcasterAgreementModalProps) {
    const termsOfAgreement = [
        t`1. You are at least 18 years old or the age of majority in your jurisdiction, whichever is older (the "Age of Majority");`,
        t`2. Anyone appearing on camera with you has also reached the Age of Majority;`,
        t`3. At no time will anyone who has not reached the Age of Majority be present while you are using the Chaturbate service;`,
        t`4. You will not permit anyone who has not reached the Age of Majority to use the Chaturbate service; and`,
        t`5. You will immediately report by email to support@chaturbate.com any and all instances you become aware of wherein an individual has used the Chaturbate service without first reaching the Age of Majority.`,
        t`6. Obviously, you consent to and request your image being broadcast on the Chaturbate platform.`,
        t`7. You agree that you will be governed by the Chaturbate Terms and by the Chaturbate Broadcaster Agreement.`,
        t`8. You agree that only age verified broadcasters associated with this room may broadcast in this room.`,
    ]

    const agreeCallback = () => {
        localStorage.setItem(mobileBroadcastTermsAgreed, "true")
        onAgreement()
    }

    return (
        <Overlay
            className="broadcasterAgreementModal"
            data-testid="broadcaster-agreement-modal"
            {...props}
            onClick={() => {}}
        >
            <div className="broadcasterAgreement">
                <Typography
                    size={Sizes.mdpx}
                    color={colorClass.brandBlue}
                    className="broadcasterAgreement__warning"
                >
                    <Trans>
                        WARNING: THIS SITE HAS A ZERO TOLERANCE POLICY ON
                        UNDERAGE BROADCASTING.
                    </Trans>
                </Typography>
                <div className="broadcasterAgreement__terms">
                    <Typography
                        size={Sizes.xs}
                        className="broadcasterAgreement__terms__text"
                    >
                        <Trans>
                            The Chaturbate service is for use by adults only. By
                            clicking the link below, you are affirming your
                            representation and warranty to us that:
                        </Trans>
                    </Typography>
                    <div className="broadcasterAgreement__terms__list">
                        {termsOfAgreement.map((term, index) => (
                            <div key={index}>
                                <Typography
                                    size={Sizes.xs}
                                    className="broadcasterAgreement__terms__list__item"
                                >
                                    {term}
                                </Typography>
                            </div>
                        ))}
                    </div>
                    <Typography
                        size={Sizes.xs}
                        className="broadcasterAgreement__terms__text"
                    >
                        <Trans>
                            If we suspect, in our sole discretion, that you are
                            using the Chaturbate service without having complied
                            in every respect with the foregoing statements, your
                            account will be terminated immediately, and we will
                            report your conduct to law enforcement.
                        </Trans>
                    </Typography>
                </div>
                <Button
                    className="broadcasterAgreement__button"
                    size={ButtonSize.Large}
                    text={t`I AGREE`}
                    onClick={agreeCallback}
                />
            </div>
        </Overlay>
    )
}
