import { useEffect, useState } from "react"
import "./PwaNotification.scss"
import { t, Trans } from "@lingui/macro"
import { durationToSeconds } from "@multimediallc/web-utils"
import {
    getLocalStorageWithExpiration,
    setLocalStorageWithExpiration,
} from "@multimediallc/web-utils/storage"
import { addPageAction } from "../../../../utils/newrelic"
import { AppleShareIcon } from "../../atoms/Icons/Others"
import {
    Notification,
    NotificationPosition,
} from "../Notification/Notification"
import type { Duration } from "@multimediallc/web-utils"

interface PwaNotificationProps {
    source: PwaNotificationSource
    delay?: Duration
}

export enum PwaNotificationSource {
    homepage = "Homepage",
    follow = "Follow",
}

export function PwaNotification({ source, delay }: PwaNotificationProps) {
    const [show, setShow] = useState(false)

    useEffect(() => {
        let timeout: number | undefined
        const onAfterDelay = (): void => {
            setShow(true)
            addPageAction("PWANotificationDisplayed", { source })
        }

        if (delay !== undefined) {
            timeout = window.setTimeout(
                onAfterDelay,
                durationToSeconds(delay) * 1000,
            )
        } else {
            onAfterDelay()
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [delay, source])

    const localStorageKey = "pwaNotificationShown"
    const testId = "pwa-notification"
    const wasDismissed = (): boolean =>
        getLocalStorageWithExpiration(localStorageKey) === "true"
    const onCloseClick = (): void => {
        setShow(false)
        setLocalStorageWithExpiration(localStorageKey, "true", { days: 180 })
        addPageAction("PWANotificationClosed", { source })
    }

    if (wasDismissed()) {
        return <div data-testid={testId} />
    }
    return (
        <Notification
            data-testid={testId}
            className="cbr-pwa-notification"
            position={NotificationPosition.bottom}
            title={t`Stay updated with the Chaturbate App`}
            onCloseClick={onCloseClick}
            show={show}
        >
            <Trans>
                Simply tap{" "}
                <AppleShareIcon className="cbr-pwa-notification__shareIcon" />{" "}
                then &quot;Add to Home Screen&quot;
            </Trans>
        </Notification>
    )
}
