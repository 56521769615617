import type { IUserInfo } from "../../interfaces/userMenu"

export function usernameColorClass(u: IUserInfo): string {
    switch (true) {
        case u.isBroadcaster:
        case u.isSitewideBroadcaster:
            return "broadcaster"
        case u.isMod:
            return "mod"
        case u.inFanclub:
            return "inFanclub"
        case u.tippedTonsRecently:
            return "tippedTonsRecently"
        case u.tippedALotRecently:
            return "tippedALotRecently"
        case u.tippedRecently:
            return "tippedRecently"
        case u.hasTokens:
            return "hasTokens"
        default:
            return "defaultUser"
    }
}
