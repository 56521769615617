import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgLiveRing = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 48 48"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <circle
            cx={24}
            cy={24}
            r={23}
            stroke="url(#liveRing_svg__a)"
            strokeWidth={2}
        />
        <defs>
            <linearGradient
                id="liveRing_svg__a"
                x1={8}
                x2={40.667}
                y1={5.333}
                y2={42.667}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#009BD6" />
                <stop offset={1} stopColor="#FF4BA9" />
            </linearGradient>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgLiveRing)
const Memo = memo(ForwardRef)
export default Memo
