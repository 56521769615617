import { useEffect } from "react"
import { useAppDispatch } from "../hooks"
import { updateTokenBalance } from "../userSlice"
import type { TokenBalanceUpdate } from "../../components/messaging/types"
import type { MobileBroadcastTopics } from "../../components/mobile_broadcast/types"

type TokenUpdateProps = {
    userTokenUpdateTopic: MobileBroadcastTopics["UserTokenUpdateTopic"]
    uuid: string | undefined
}

export const useTokenUpdateEffect = ({
    userTokenUpdateTopic,
    uuid,
}: TokenUpdateProps): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const dispatchTokenUpdate = (event: TokenBalanceUpdate) => {
            dispatch(updateTokenBalance(event.tokens))
        }

        if (uuid !== undefined) {
            const userTokenUpdateTopicInstance = new userTokenUpdateTopic(uuid)
            userTokenUpdateTopicInstance.onMessage.listen(dispatchTokenUpdate)
            return () =>
                userTokenUpdateTopicInstance.onMessage.removeListener(
                    dispatchTokenUpdate,
                )
        }
    }, [uuid])
}
