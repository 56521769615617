// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/sorting.svg?3cf5d88593ea", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileSort{display:block}.MobileSort.hidden{display:None}.MobileSortButton{margin:0;padding:0;display:block;width:40px;height:40px;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-size:24px 24px;background-position:center center;background-repeat:no-repeat;background-color:rgba(0,0,0,0);border:none;color:rgba(0,0,0,0);pointer-events:none}.MobileSortButton.active::after{content:"";position:absolute;top:4px;right:4px;display:block;background:#68b5f0;width:8px;height:8px;border-radius:50%}.MobileSortOption{opacity:0;position:absolute;top:50%;transform:translateY(-50%);right:0;display:block;width:40px;height:40px;cursor:pointer;font-size:20px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
