import { useCallback, useEffect, useRef, useState } from "react"
import type { ComponentPropsWithoutRef } from "react"
import { checkDevicePermsGranted } from "@multimediallc/web-utils"
import { useBroadcastPushEffect } from "../../../hooks/useBroadcastPushEffect"
import {
    BroadcasterAgreementModal,
    hasBroadcasterAgreed,
} from "../BroadcasterAgreementModal/BroadcasterAgreementModal"
import { DevicePermissionsPrompt } from "../DevicePermissionsPrompt"
import { MobileBroadcastLandscapeUI } from "../MobileBroadcastLandscapeUI"
import { MobileBroadcastPortraitUI } from "../MobileBroadcastPortraitUI"
import { useIsPortrait } from "./hooks"
import type { MobileBroadcastTopics } from "../types"

import "./MobileBroadcast.scss"

export interface MobileBroadcastProps {
    readyForStreamer: () => void
    isStreaming: boolean
    onRecordButtonClick: () => void
    videoEl: HTMLVideoElement
    topics: MobileBroadcastTopics
    roomUid: string | undefined
}

export function MobileBroadcast({
    readyForStreamer,
    videoEl,
    onRecordButtonClick,
    isStreaming,
    topics,
    roomUid,
}: MobileBroadcastProps) {
    useBroadcastPushEffect({ topics, roomUid })

    const [broadcastTermsAccepted, setBroadcastTermsAccepted] =
        useState<boolean>(hasBroadcasterAgreed())
    const [devicePermsGrantedOrAcked, setDevicePermsGrantedOrAcked] = useState<
        boolean | undefined
    >(undefined)
    const streamerInitialized = useRef<boolean>(false)

    const onTermsAgreement = useCallback(
        () => setBroadcastTermsAccepted(true),
        [],
    )
    const onPermsAck = useCallback(() => setDevicePermsGrantedOrAcked(true), [])
    const isPortrait = useIsPortrait()

    useEffect(() => {
        void checkDevicePermsGranted().then((permsInitiallyGranted) => {
            setDevicePermsGrantedOrAcked(permsInitiallyGranted)
        })
    }, [])

    if (!broadcastTermsAccepted) {
        return (
            <MobileBroadcastWrapper>
                <BroadcasterAgreementModal onAgreement={onTermsAgreement} />
            </MobileBroadcastWrapper>
        )
    }

    if (devicePermsGrantedOrAcked !== true) {
        return (
            <MobileBroadcastWrapper>
                {devicePermsGrantedOrAcked === false && (
                    <DevicePermissionsPrompt onPermsAck={onPermsAck} />
                )}
            </MobileBroadcastWrapper>
        )
    }

    return (
        <MobileBroadcastWrapper>
            <div
                className="MobileBroadcast__videoWrapper"
                data-testid="mobilebroadcast-video-wrapper"
                ref={(ref) => {
                    ref?.appendChild(videoEl)
                    if (!streamerInitialized.current) {
                        // Init MobileStreamer when and only when the videoEl is first added to the DOM.
                        // MobileStreamer init errors if the videoEl is not in DOM.
                        readyForStreamer()
                        streamerInitialized.current = true
                    }
                }}
            />
            {isPortrait ? (
                <MobileBroadcastPortraitUI />
            ) : (
                <MobileBroadcastLandscapeUI
                    isStreaming={isStreaming}
                    onRecordButtonClick={onRecordButtonClick}
                />
            )}
        </MobileBroadcastWrapper>
    )
}

function MobileBroadcastWrapper(props: ComponentPropsWithoutRef<"div">) {
    return <div className="MobileBroadcast" {...props} />
}
