import { useEffect, useRef, useState } from "react"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../../../hooks/urlStateContext"
import { useDebounce } from "../../../../../../../hooks/useDebounce"
import { error } from "../../../../../../../utils/debug"
import { Search } from "../../../../../../common/atoms/Icons/Others"
import { searchSpokenLanguages } from "../../../../../LanguageFilterSection"
import { MAX_INPUT_LENGTH } from "../../../../../LanguageFilterSection/languageUtils"
import "../../filterSearch.scss"
import { useFiltersContext } from "../../../FiltersContext"
import { SearchAutocomplete } from "../../SearchAutocomplete"
import type { ILanguage } from "../../../../../LanguageFilterSection"
import type { IAutocompleteItem } from "../../SearchAutocomplete/SearchAutocomplete"

export const LanguageSearch = () => {
    const { languageSearchInput, setLanguageSearchInput } = useFiltersContext()

    const [languagesFromSearch, setLanguagesFromSearch] = useState<ILanguage[]>(
        [],
    )
    const [autocompleteItems, setAutocompleteItems] = useState<
        IAutocompleteItem[]
    >([])
    const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const searchInputElement = useRef<HTMLInputElement>(null)
    const debouncedSearchValue = useDebounce<string>(languageSearchInput, 100)

    const urlStateContext = useUrlStateContext()
    const selectedLanguage = urlStateContext.spokenLanguages?.[0] ?? ""

    const autoCompleteRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setIsLoading(true)
        searchSpokenLanguages({ search: debouncedSearchValue })
            .then((languages) => {
                setLanguagesFromSearch(
                    languages.toSorted((a, b) =>
                        a.display_name.localeCompare(b.display_name),
                    ),
                )
            })
            .catch((e) => error(e))
            .finally(() => {
                setIsLoading(false)
            })
    }, [showAutoComplete, debouncedSearchValue, setLanguagesFromSearch])

    useEffect(() => {
        setAutocompleteItems(
            languagesFromSearch.map((languageData) => {
                return {
                    displayName: languageData.display_name,
                    value: languageData.code,
                }
            }),
        )
    }, [languagesFromSearch])

    const applyLanguage = (language: string) => {
        if (selectedLanguage !== language) {
            setLanguageSearchInput("")
            UrlState.current.setPartialState({ spokenLanguages: [language] })
        }
        setShowAutoComplete(false)
    }

    const handleAutocompleteBlur = (e: MouseEvent) => {
        if (
            !autoCompleteRef.current?.contains(e.target as Node) &&
            !searchInputElement.current?.contains(e.target as Node)
        ) {
            setShowAutoComplete(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleAutocompleteBlur)
        return () => {
            document.removeEventListener("click", handleAutocompleteBlur)
        }
    }, [])

    return (
        <div className="FilterSearch">
            <div className="FilterSearch__input-container">
                <div
                    className="FilterSearch__Button"
                    data-testid="filter-language-search-button"
                >
                    <Search />
                </div>
                <input
                    className="FilterSearch__Input"
                    data-testid="language-search-input"
                    placeholder="Search languages"
                    maxLength={MAX_INPUT_LENGTH}
                    value={languageSearchInput}
                    onChange={(e) => {
                        setLanguageSearchInput(e.target.value)
                    }}
                    onFocus={() => {
                        setShowAutoComplete(true)
                    }}
                    ref={searchInputElement}
                />
            </div>

            {showAutoComplete && (
                <div
                    className="FilterSearch__autocomplete-container"
                    ref={autoCompleteRef}
                >
                    <SearchAutocomplete
                        items={autocompleteItems}
                        onItemClick={applyLanguage}
                        loading={isLoading}
                    />
                </div>
            )}
        </div>
    )
}

LanguageSearch.displayName = "LanguageSearch"
