import {
    getAPIParamsFromURLState,
    getNonReservedQueryParamValues,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { Gender } from "@multimediallc/gender-utils"
import { generateApiPath } from "../../../../../../routes/util"
import { fetchGet } from "../../../../../../utils/myFetch"

const TOP_TAGS_FETCH_COUNT = 1000

export interface TopTagsResponse {
    all_tags: string[]
}

export async function loadPopularitySortedTags(): Promise<string[]> {
    /**
     * Returns a list of tags with initial tags being personalized for a logged-in user and the rest being sorted on popularity
     * Tags here can are filtered on only gender.
     */
    const gendersForApi = (UrlState.current.state.genders ?? []).map((g) =>
        g === Gender.Trans ? Gender.OldTrans : g,
    )
    const urlQuery = new URLSearchParams([
        ["count", String(TOP_TAGS_FETCH_COUNT)],
        ...gendersForApi.map((g) => ["genders", g]),
    ])

    const apiPath = `${generateApiPath("en", "hashtags", "top_tags/")}?${urlQuery.toString()}`
    return fetchGet(apiPath).then((resp) => {
        const jsonData = resp.jsonData as TopTagsResponse
        if ("all_tags" in jsonData) {
            return jsonData.all_tags
        } else {
            throw new Error("Missing 'all_tags' key in /top_tags response data")
        }
    })
}

export async function loadRoomlistFilteredTags(): Promise<string[]> {
    /**
     * Returns a list of tags that are filtered based on the roomlist filters. These are sorted alphabetically.
     */
    const buildTagSearchApiUrl = (): string => {
        // Fetches all unique hashtags with the rooms matching current roomlist filters
        const filters = getAPIParamsFromURLState(UrlState.current.state)
        const queryParams = new URLSearchParams({
            ...getNonReservedQueryParamValues(),
            ...(filters as Record<string, string>),
        })
        queryParams.sort() // Sort params to ensure request URLs with identical filters correspond 1:1

        return `${generateApiPath("en", "roomlist", "all-tags/")}?${queryParams.toString()}`
    }

    const apiUrl = buildTagSearchApiUrl()
    return fetchGet(apiUrl).then((resp) => {
        const jsonData = resp.jsonData as TopTagsResponse
        if ("all_tags" in jsonData) {
            return jsonData.all_tags
        } else {
            throw new Error("Missing 'all_tags' key in /all-tags response data")
        }
    })
}

interface ITagFromSearch {
    hashtag: string
    room_count: number
}

interface ITagsFromSearchResponse {
    tags: ITagFromSearch[]
}

export async function loadTagsFromSearch(keyword: string): Promise<string[]> {
    const buildTagSearchApiUrl = (keyword: string): string => {
        const gendersForApi = (UrlState.current.state.genders ?? []).map((g) =>
            g === Gender.Trans ? Gender.OldTrans : g,
        )

        const urlQuery = new URLSearchParams([
            ["field", "room_count"],
            ["search", keyword],
            ...gendersForApi.map((g) => ["genders", g]),
        ])

        return `${generateApiPath("en", "hashtags", "search_get_data/")}?${urlQuery.toString()}`
    }

    const apiUrl = buildTagSearchApiUrl(keyword)

    return fetchGet(apiUrl).then((resp) => {
        const jsonData = resp.jsonData as ITagsFromSearchResponse
        if ("tags" in jsonData) {
            return jsonData.tags.map((data) => data.hashtag)
        } else {
            throw new Error(
                "Missing 'tags' key in /hashtags/search_get_data response",
            )
        }
    })
}
