import { createContext, useContext, useEffect, useState } from "react"
import { ALL, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

export const UrlStateContext = createContext<IURLState | undefined>(undefined)

interface UrlStateContextProviderProps {
    children: React.ReactNode
}

export function UrlStateContextProvider({
    children,
}: UrlStateContextProviderProps) {
    const [urlState, setUrlState] = useState<IURLState>(UrlState.current.state)

    useEffect(() => {
        UrlState.current.listenGlobal(ALL, (state) => {
            setUrlState(state)
        })
    }, [])

    return (
        <UrlStateContext.Provider value={urlState}>
            {children}
        </UrlStateContext.Provider>
    )
}

export function useUrlStateContext(): IURLState {
    const context = useContext(UrlStateContext)
    if (!context) {
        throw new Error(
            "useUrlStateContext must be used within a UrlStateContextProvider",
        )
    }
    return context
}
