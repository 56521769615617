import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgAppleShareIcon = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 30 30"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect width={30} height={30} fill="#F8F9FA" rx={15} />
        <path
            fill="#2E7CF6"
            d="M15.004 17.45c.322 0 .59-.256.59-.554v-7.28l-.044-1.063.501.503 1.138 1.148a.56.56 0 0 0 .404.17c.307 0 .546-.212.546-.503 0-.149-.067-.262-.18-.369l-2.529-2.31C15.28 7.05 15.153 7 15.004 7c-.157 0-.285.05-.434.191l-2.53 2.311c-.112.107-.18.22-.18.37 0 .29.225.502.54.502a.58.58 0 0 0 .411-.17l1.138-1.148.501-.503-.045 1.063v7.28c0 .298.277.553.599.553M10.709 23h8.582c1.564 0 2.35-.737 2.35-2.198v-7.074c0-1.46-.786-2.198-2.35-2.198h-2.087v1.141h2.065c.74 0 1.167.383 1.167 1.12v6.948c0 .737-.426 1.12-1.167 1.12h-8.545c-.749 0-1.16-.383-1.16-1.12V13.79c0-.737.411-1.12 1.16-1.12h2.072V11.53H10.71c-1.564 0-2.35.737-2.35 2.198v7.074c0 1.46.786 2.198 2.35 2.198"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgAppleShareIcon)
const Memo = memo(ForwardRef)
export default Memo
