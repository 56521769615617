import { t } from "@lingui/macro"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import "./ActiveTagHeader.scss"

interface ActiveTagHeaderProps {
    activeHashtag?: string
    returnToTagsIndex?: boolean
}

export function ActiveTagHeader({
    activeHashtag,
    returnToTagsIndex,
}: ActiveTagHeaderProps) {
    if (activeHashtag === undefined) {
        return null
    }

    const targetHref =
        returnToTagsIndex === true
            ? "/tags/"
            : UrlState.current.getURLForPartialState({ tags: undefined })

    return (
        <a
            className="ActiveTagHeader"
            href={targetHref}
            data-testid="tag-header-link"
            onClick={(event) => {
                if (!returnToTagsIndex) {
                    event.preventDefault()
                    UrlState.current.setPartialState({ tags: undefined })
                }
            }}
        >
            <h1 data-testid="mobile-tag-page-header">
                {t`#${activeHashtag} Cams`}
            </h1>
        </a>
    )
}
