// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AGVaMMDFt7dD6oXhEitM{display:flex;justify-content:space-between;position:fixed;bottom:0;width:calc(100% - 32px);padding:16px 0}.AGVaMMDFt7dD6oXhEitM button{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;font-size:16px;width:calc(50% - 4px);padding:16px 0;border-radius:8px;border:none}.AGVaMMDFt7dD6oXhEitM button.wjJ0rf2F5g_OtmLvOZQ1{background-color:#fff;color:#0c6a93;border:1px solid #0c6a93}.AGVaMMDFt7dD6oXhEitM button.zpQQzYbfvjvk5is6qi7i{background-color:#0c6a93;color:#fff}.AGVaMMDFt7dD6oXhEitM button.zpQQzYbfvjvk5is6qi7i:disabled{background-color:#c9c9c9;color:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AGVaMMDFt7dD6oXhEitM`,
	"cancel": `wjJ0rf2F5g_OtmLvOZQ1`,
	"save": `zpQQzYbfvjvk5is6qi7i`
};
export default ___CSS_LOADER_EXPORT___;
