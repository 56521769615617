import type React from "react"
import { useRef, useState } from "react"
import "../filterSections/filterSection.scss"
import { CloseButton } from "../../../../common/atoms/Icons/Others"

export const DRAG_DY_THRESHOLD = 80

interface FiltersDrawerProps {
    onHideSection: () => void
    children: React.ReactNode
}

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
    onHideSection,
    children,
}) => {
    const [position, setPosition] = useState(0)
    const startY = useRef(0)
    const [isDragging, setIsDragging] = useState(false)

    const handleDragStart = (e: React.TouchEvent | React.MouseEvent) => {
        const clientY = "touches" in e ? e.touches[0].clientY : e.clientY

        setIsDragging(true)
        startY.current = clientY
    }

    const handleDragMove = (e: React.TouchEvent | React.MouseEvent) => {
        if (!isDragging) return
        const clientY = "touches" in e ? e.touches[0].clientY : e.clientY
        const delta = clientY - startY.current
        if (delta > 0) {
            // Only drag down
            setPosition(delta)
        }
    }

    const handleDragEnd = () => {
        setIsDragging(false)
        if (position > DRAG_DY_THRESHOLD) {
            // Close if dragged far enough
            onHideSection()
        } else {
            // Snap back if not dragged far enough
            setPosition(0)
        }
    }

    return (
        <div
            className={`FilterSection${position > 0 && isDragging ? " FilterSection--dragging" : ""}`}
            data-testid="mobile-filters-drawer"
            style={{
                transform: `translateY(${position}px)`,
                transition: isDragging ? "none" : "transform 0.3s ease",
            }}
            onMouseDown={handleDragStart}
            onMouseMove={handleDragMove}
            onMouseUp={handleDragEnd}
            onTouchStart={handleDragStart}
            onTouchMove={handleDragMove}
            onTouchEnd={handleDragEnd}
        >
            <div className="FilterSection__bar" />
            <CloseButton
                className="FilterSection__close-button"
                onClick={onHideSection}
                data-testid="mobile-filters-section-close-button"
            />
            {children}
        </div>
    )
}
